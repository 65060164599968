<template>
  <div style="zoom: 0.8">
    <div class="header">
      <div class="top" style="display: flex; justify-content: space-between">
        <div>
          <i class="el-icon-search"></i>
          <span> 搜索列表</span>
        </div>
        <div class="button">
          <div>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-search"
              @click="AdminSearchUser()"
              >搜索</el-button
            >
          </div>
          <div>
            <el-button
              size="mini"
              type=""
              icon="el-icon-refresh"
              @click="Refresh()"
              >重置</el-button
            >
          </div>
          <div>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-plus"
              @click="AddHistory()"
              >新增</el-button
            >
          </div>
        </div>
      </div>
      <div class="bottom" style="margin-top: 2%">
        <el-form style="margin-left: 2%" :inline="true">
          <el-form-item label="活动名称">
            <div @keyup.enter="GetMemberList()">
              <el-input size="small" v-model="form.activeName"></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="center" style="margin-top: 2%">
      <el-table :data="histories">
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="活动标题" prop="historyTitle"></el-table-column>
        <el-table-column
          label="活动子标题"
          prop="historySubTitle"
        ></el-table-column>
        <el-table-column label="活动时间" prop="historyTime"></el-table-column>
        <el-table-column width="250" label="操作" prop="">
          <template slot-scope="scope">
            <div>
              <el-button
                style="background-color: #395667; color: white"
                size="mini"
                @click="OpenParticulars(histories[scope.$index].id)"
                >详情</el-button
              >
              <el-button
                style="background-color: #cf3c60; color: white"
                size="mini"
                @click="DeleteMemberOne(histories[scope.$index].id)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination
        :total="total"
        background=""
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      histories: [], //查询数据
      id: "", //用户id数据
      total: 0, //条数信息
      form: {
        skip: 1, //分页数据
        activeName: "", //搜索内容
      }, //查询数据
    };
  },
  mounted() {
    this.$bus.$on("UpdateHistory", () => {
      this.GetMemberList();
    });
    this.GetMemberList();
  },
  methods: {
    //新增数据
    AddHistory() {
      this.$router.push("/manage/history/add");
    },
    //删除单条数据
    DeleteMemberOne(id) {
      this.$axios.get(`/Admin/AdminDeleteHistory?id=${id}`).then((res) => {
        if (res.data.code == 200) {
          this.$notify.success({ message: "删除成功" });
          this.GetMemberList();
        } else {
          this.$notify.error({ message: res.data.msg });
        }
      });
    },
    //打开详情页并获取详情数据
    OpenParticulars(e) {
      this.$router.push({
        path: "/manage/history/update",
        query: {
          id: e,
        },
      });
    },
    //设置翻页
    onCurrentChange(e) {
      this.form.skip = e;
      this.GetMemberList();
    },
    //重置按钮
    Refresh() {
      this.form.activeName = "";
      this.GetMemberList();
    },
    AdminSearchUser() {
      if (this.form.activeName == "" || this.form.activeName == "\\") return;
      this.GetMemberList();
    },
    //获取用户条数和首页用户10条信息
    GetMemberList() {
      this.$axios.post(`/Admin/GetHistoryRanking`, this.form).then(
        (res) => {
          if (res.data.code == 200) {
            this.histories = res.data.data.records;
            this.total = res.data.data.total;
          }
        },
        (err) => {
          this.$message.error({ message: "系统维护中..." });
          console.log(err);
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  padding: 1vh;
  .button {
    display: flex;
    justify-content: space-between;
  }

  .button > div {
    flex-grow: 1;
    margin: 5px;
  }
}
.center {
}
.footer {
  margin-top: 2%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2vh;
}
</style>