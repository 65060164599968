<template>
  <div>
    <el-dialog
      title="修改"
      :visible.sync="dialogFormVisible"
      :before-close="beforeClose"
    >
      <el-form
        :model="form"
        label-width="100px"
        :rules="rules"
        ref="formNameQuestion"
      >
        <el-form-item label="编号" prop="id">
          <el-input
            size="mini"
            style="width: 80%"
            v-model="form.id"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="题目" prop="topic">
          <el-input
            size="mini"
            type="textarea"
            style="width: 80%"
            v-model="form.topic"
          ></el-input>
        </el-form-item>
        <el-form-item label="答案" prop="answer">
          <el-input
            size="mini"
            type="textarea"
            style="width: 80%"
            v-model="form.answer"
          ></el-input>
        </el-form-item>
        <el-form-item label="分数" prop="score">
          <el-input
            size="mini"
            style="width: 80%"
            v-model="form.score"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="beforeClose">取 消</el-button>
        <el-button
          size="mini"
          type="primary"
          @click="submitForm('formNameQuestion')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogFormVisible: true,
      form: {
        id: "",
        topic: "",
        answer: "",
        score: "",
      },
      rules: {
        id: [{ required: true, message: "请输入编号" }],
        topic: [{ required: true, message: "请输入题目" }],
        answer: [{ required: true, message: "请输入答案" }],
        score: [{ required: true, message: "请输入分数" }],
      },
    };
  },
  mounted() {
    this.form.id = this.$route.query.id;
    this.GetQuestionOne();
  },
  methods: {
    //获取单题数据
    GetQuestionOne() {
      this.$axios
        .get(`/Admin/GetQuestionOne?id=${this.form.id}`)
        .then((res) => {
          if (res.data.code == 200) {
            this.form = res.data.data;
          }
        });
    },
    //表单验证
    submitForm(formNameQuestion) {
      this.$refs[formNameQuestion].validate((valid) => {
        if (valid) {
          this.Submit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //确定提交修改
    Submit() {
      this.$axios.post("/Admin/UpdateQuestion", this.form).then((res) => {
        if (res.data.code == 200) {
          this.$notify.success({ message: "修改成功" });
          this.beforeClose();
          this.$bus.$emit("UpdateQuestion");
        } else {
          this.$notify.error({ message: "修改失败" });
        }
      });
    },
    //路由回退
    beforeClose() {
      this.$router.back();
    },
  },
};
</script>

<style>
</style>