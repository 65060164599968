<template>
  <div>
    <el-dialog
      title="详情"
      :visible.sync="dialogFormVisible"
      :before-close="beforeClose"
    >
      <el-form :model="form" label-width="100px" :rules="rules" ref="formName">
        <el-form-item label="编号" prop="id">
          <el-input
            size="mini"
            style="width: 80%"
            v-model="form.id"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="学号" prop="studentNumber">
          <el-input
            size="mini"
            style="width: 80%"
            v-model="form.studentNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="nickname">
          <el-input
            size="mini"
            style="width: 80%"
            v-model="form.nickname"
          ></el-input>
        </el-form-item>
        <el-form-item label="专业" prop="major">
          <el-input
            size="mini"
            style="width: 80%"
            v-model="form.major"
          ></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="username">
          <el-input
            size="mini"
            style="width: 80%"
            v-model="form.username"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            size="mini"
            style="width: 80%"
            v-model="form.password"
          ></el-input>
        </el-form-item>
        <el-form-item label="在线状态" prop="status">
          <el-select v-model="form.status">
            <el-option value="0"></el-option>
            <el-option value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="beforeClose">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitForm('formName')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogFormVisible: true,
      form: {
        id: "",
        studentNumber: "",
        nickname: "",
        major: "",
        username: "",
        password: "",
        status: "",
      },
      rules: {
        studentNumber: [{ required: true, message: "请输入学号" }],
        nickname: [{ required: true, message: "请输入姓名" }],
        major: [{ required: true, message: "请输入专业" }],
        username: [{ required: true, message: "请输入账号" }],
        password: [{ required: true, message: "请输入密码" }],
        status: [{ required: true, message: "请输入密码" }],
      },
    };
  },
  mounted() {
    this.form = this.$route.query;
  },
  methods: {
    //表单验证
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.Submit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //确定提交修改
    Submit() {
      this.$axios.post("/Admin/UpdateMember", this.form).then((res) => {
        if (res.data.code == 200) {
          this.$notify.success({ message: "修改成功" });
          this.beforeClose();
          this.$bus.$emit("UpdateMember");
        } else {
          this.$notify.error({ message: "修改失败" });
        }
      });
    },
    //路由回退
    beforeClose() {
      this.$router.back();
    },
  },
};
</script>

<style>
</style>