<template>
  <div id="back">
    <socket />
    <div class="main">
      <div class="stars">
        <div
          class="star"
          v-for="(item, index) in starsCount"
          :key="index"
          ref="star"
        ></div>
      </div>
      <BallImport></BallImport>
      <div
        v-show="!showRank"
        class="people"
        style="
          color: white;
          text-align: center;
          font-weight: 600;
          font-size: 3vw;
          padding-bottom: 3vh;
          cursor: pointer;
        "
        @click="getUserHeroNum"
      >
        当前在线人数：{{ people }}
      </div>
      <div class="ShowButton" v-show="!showRank">
        <!-- <input type="file" id="file" /> -->
        <!-- <el-button @click="Test()">test</el-button> -->

        <el-dropdown trigger="click" @command="handleCommand">
          <el-button style="background-color: black; color: white" type="info"
            >设置比赛状态</el-button
          >
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="0">设置为练习状态</el-dropdown-item>
            <el-dropdown-item command="1"
              >设置比赛为不可登录状态</el-dropdown-item
            >
            <el-dropdown-item command="2">设置比赛为开始状态</el-dropdown-item>
            <el-dropdown-item command="3"
              >设置比赛为不可提交状态</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-button type="primary" style="margin-left: 1vw" @click="OverSudo()"
          >结束数独比赛</el-button
        >
        <el-button
          type="info"
          style="background-color: #0a0c12; color: white"
          @click="GetOverRank()"
          >获取当前排名</el-button
        >
        <el-button type="success" @click="GetNowOnlineUser()"
          >当前在线用户</el-button
        >
        <el-dropdown trigger="click" @command="UpdateAdminBanImg">
          <el-button type="danger">设置发送状态</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="0">设置禁止发送</el-dropdown-item>
            <el-dropdown-item command="1">设置允许发送</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button type="warning" @click="ToLogin()">返回登录界面</el-button>
        <el-button type="info" @click="ToAdmin()">进入管理员后台</el-button>
      </div>
    </div>

    <div style="height: 2vh; position: fixed; top: 0px; right: 0px">
      <el-button
        @click="showRank = !showRank"
        size="mini"
        v-show="!showRank"
        icon="el-icon-arrow-down"
        style="background-color: #78746e; color: white"
      ></el-button>
    </div>
    <div class="top" :class="showRank ? 'show-rank' : ''" size="mini">
      <!-- 蒙层内容 -->
      <div style="height: 2vh; position: fixed; top: 0px; right: 0px">
        <el-button
          v-show="showRank"
          @click="showRank = !showRank"
          size="mini"
          icon="el-icon-arrow-up"
          style="background-color: #78746e; color: white"
        ></el-button>
      </div>
      <div class="overlay">
        <!-- 排行榜内容 -->
        <div class="title">英雄榜</div>
        <!-- 这里可以根据你的需求添加排行榜的具体内容 -->
        <div class="ranking-list">
          <!-- 排行榜项 -->
          <el-descriptions
            :colon="false"
            :column="7"
            style="margin-top: 2vh; margin-left: 2vw"
          >
            <el-descriptions-item>排名</el-descriptions-item>
            <el-descriptions-item>专业</el-descriptions-item>
            <el-descriptions-item>姓名</el-descriptions-item>
            <el-descriptions-item>通关</el-descriptions-item>
            <el-descriptions-item>得分</el-descriptions-item>
            <el-descriptions-item>总耗时</el-descriptions-item>
            <el-descriptions-item>是否踢出</el-descriptions-item>
          </el-descriptions>
          <div v-for="(item, index) in rankingData" :key="index">
            <el-descriptions
              :colon="false"
              :column="7"
              style="margin-top: 2vh; margin-left: 2vw"
              class="item"
              @click.native="KickOut(item)"
            >
              <el-descriptions-item>{{ index + 1 }}</el-descriptions-item>
              <el-descriptions-item>{{ item.major }}</el-descriptions-item>
              <el-descriptions-item>{{ item.nickname }}</el-descriptions-item>
              <el-descriptions-item>{{
                item.successNumber
              }}</el-descriptions-item>
              <el-descriptions-item>{{ item.score }}</el-descriptions-item>
              <el-descriptions-item>{{ item.time }}</el-descriptions-item>
              <el-descriptions-item>踢出</el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </div>
    </div>
    <!-- 当前排名弹窗 -->
    <el-dialog
      :visible="visible"
      width="60vw"
      :before-close="beforeClose"
      title="当前在线用户"
    >
      <el-descriptions
        :colon="false"
        :column="6"
        style="margin-top: 2vh; margin-left: 2vw"
      >
        <el-descriptions-item>排名</el-descriptions-item>
        <el-descriptions-item>专业</el-descriptions-item>
        <el-descriptions-item>姓名</el-descriptions-item>
        <el-descriptions-item>通关</el-descriptions-item>
        <el-descriptions-item>得分</el-descriptions-item>
        <el-descriptions-item>总耗时</el-descriptions-item>
      </el-descriptions>
      <div v-for="(item, index) in rankingOver" :key="index">
        <el-descriptions
          :colon="false"
          :column="6"
          style="margin-top: 2vh; margin-left: 2vw"
          class="item"
        >
          <el-descriptions-item>{{ index + 1 }}</el-descriptions-item>
          <el-descriptions-item>{{ item.major }}</el-descriptions-item>
          <el-descriptions-item>{{ item.nickname }}</el-descriptions-item>
          <el-descriptions-item>{{ item.successNumber }}</el-descriptions-item>
          <el-descriptions-item>{{ item.score }}</el-descriptions-item>
          <el-descriptions-item>{{ item.time }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
    <!-- 查询当前在线用户 -->
    <el-dialog
      :visible="showOnline"
      width="60vw"
      :before-close="beforeCloseOnline"
      title="Final ranking"
    >
      <el-descriptions
        :colon="false"
        :column="4"
        style="margin-top: 2vh; margin-left: 2vw"
      >
        <el-descriptions-item>连接ID</el-descriptions-item>
        <el-descriptions-item>用户ID</el-descriptions-item>
        <el-descriptions-item>专业</el-descriptions-item>
        <el-descriptions-item>姓名</el-descriptions-item>
      </el-descriptions>
      <div v-for="(item, index) in onlineList" :key="index">
        <el-descriptions
          :colon="false"
          :column="4"
          style="margin-top: 2vh; margin-left: 2vw"
          class="item"
        >
          <el-descriptions-item>{{ item.onlineId }}</el-descriptions-item>
          <el-descriptions-item>{{ item.id }}</el-descriptions-item>
          <el-descriptions-item>{{ item.major }}</el-descriptions-item>
          <el-descriptions-item>{{ item.nickname }}</el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BallImport from "@/components/common/BallImport.vue";
//导入socket连接
import socket from "@/components/socket/WebSocket.vue";

export default {
  components: { BallImport, socket },
  data() {
    return {
      showRank: false, //是否显示蒙板
      rankingData: [], //排名数据
      visible: false, //是否可见
      rankingOver: [], //排名
      onlineList: [], //在线用户
      starsCount: 800, //数量
      distance: 600, //间距
      id: localStorage.getItem("id"), //获取用户用户id
      people: 0, //当前人数
      showOnline: false, //查询当前在线用户
    };
  },
  mounted() {
    //判断是否为管理员
    this.ifAdmin();
    //进行背景渲染
    this.init();
    //获取排行榜数据信息
    this.GetRankingData();
    // 获取当前竞赛人数
    this.getUserHeroNum();
  },
  methods: {
    // 获取当前在线用户
    GetNowOnlineUser() {
      this.$axios.get("/Admin/onlineUser").then((res) => {
        if (res.data.code == 200) {
          this.showOnline = true;
          //提示管理员修改成功
          this.onlineList = res.data.data;
        } else {
          this.$notify.error({ message: res.data.msg, position: "top-left" });
        }
      });
    },
    // 处理管理员获取当前人数
    getUserHeroNum() {
      this.$axios.get(`/Admin/getUserHeroNum`).then((res) => {
        if (res.data.code == 200) {
          //提示管理员修改成功
          this.people = res.data.data;
        } else {
          this.$notify.error({ message: "修改失败", position: "top-left" });
        }
      });
    },
    // 返回登录界面
    ToLogin() {
      this.$router.replace("/login");
    },
    // 设置禁止发送
    UpdateAdminBanImg(item) {
      this.$axios.get(`/Admin/UpdateAdminBanImg/${item}`).then((res) => {
        if (res.data.code == 200) {
          //提示管理员修改成功
          this.$notify.success({ message: "修改成功", position: "top-left" });
        } else {
          this.$notify.error({ message: "修改失败", position: "top-left" });
        }
      });
    },
    //获取排行榜数据
    GetRankingData() {
      //发起请求获取排行榜数据信息
      this.GetRankingDataList();
      //发起请求获取排行榜数据信息
      this.$bus.$on("getRanking", () => {
        //发起请求获取数据
        this.GetRankingDataList();
      });
    },
    //发起请求获取排行榜数据
    GetRankingDataList() {
      //发起请求
      this.$axios.get("/user/getUserHero").then((res) => {
        //循环排行榜数组,如果里面的id都没有自己的id,则直接顶出去用户
        this.rankingData = res.data.data;
      });
    },
    //进行背景渲染
    init() {
      let starArr = this.$refs.star;
      starArr.forEach((item) => {
        var speed = 0.2 + Math.random() * 1;
        var distance = this.distance + Math.random() * 300;
        item.style.transformOrigin = `0 0 ${distance}px`;
        item.style.transform = `translate3d(0,0,-${distance}px) rotateY(${
          Math.random() * 360
        }deg) rotateX(${Math.random() * -50}deg) scale(${speed},${speed})`;
      });
    },
    //踢出用户在线状态
    KickOut(item) {
      this.$confirm(
        "您确定将" + item.major + "-" + item.nickname + "踢出比赛吗？",
        "计算机协会"
      )
        .then(() => {
          //发起请求执行该操作
          this.$axios.get(`/Admin/kickOut?id=${item.id}`).then((res) => {
            //如果成功则提出
            if (res.data.code == 200) {
              this.$notify.warning({
                message: "已为您执行此操作！",
                position: "top-left",
              });
              return;
            }
            //提示用户
            this.$notify.error({
              message: res.data.msg,
              position: "top-left",
            });
          });
        })
        .catch(() => {});
    },
    //点击事件
    handleCommand(command) {
      switch (command) {
        case "0":
          this.UpdateStatus("0");
          break;
        case "1":
          this.UpdateStatus("1");
          break;
        case "2":
          this.BeginGame();
          break;
        case "3":
          this.UpdateStatus("3");
          break;
      }
    },
    //修改当前状态
    UpdateStatus(status) {
      //发送请求修改
      this.$axios.get(`/Admin/UpdateStatus?status=${status}`).then((res) => {
        if (res.data.code == 200) {
          //发送消息给后端
          let message = "UpdateStatus";
          //发送消息
          this.$websocket.send(message);
          //提示管理员修改成功
          this.$notify.success({ message: "修改成功", position: "top-left" });
        } else {
          this.$notify.error({ message: "修改失败", position: "top-left" });
        }
      });
    },
    //开启正式比赛
    BeginGame() {
      this.$confirm("开启正式比赛将清空练习数据", "计算机协会", {
        type: "warning",
      })
        .then(() => {
          this.$axios.get("/Admin/BeginGame").then((res) => {
            if (res.data.code == 200) {
              let message = "UpdateStatus";
              this.$websocket.send(message);
              this.$notify.success({
                message: "开启成功",
                position: "top-left",
              });
            }
          });
        })
        .catch(() => {});
    },
    //进入管理员后台
    ToAdmin() {
      this.$prompt("请输入密钥", "计算机协会", { type: "info" })
        .then((value) => {
          if (value.value == "admin") {
            this.$router.push("/manage/user");
          } else {
            this.$message.error({ message: "密钥错误" });
          }
        })
        .catch(() => {});
    },
    //关闭弹窗dialog
    beforeClose() {
      this.visible = false;
    },
    beforeCloseOnline() {
      this.showOnline = false;
    },
    //结束数独比赛
    OverSudo() {
      //询问管理员
      this.$confirm("确认结束数独比赛?", "计算机协会", { type: "warning" })
        .then(() => {
          //发起请求
          this.$axios.get("/Admin/kickOutAllUser").then((res) => {
            //管理员踢出所有用户
            if (res.data.code == 200) {
              this.$notify.success({
                message: res.data.data,
                position: "top-left",
              });
              return;
            }
            this.$notify.error({
              message: res.data.msg,
              position: "top-left",
            });
          });
        })
        .catch(() => {});
    },
    //获取最终排名
    GetOverRank() {
      this.$axios.get("/Admin/GetOverRank").then((res) => {
        this.visible = true;
        this.rankingOver = res.data.data;
      });
    },
    //判断是否为管理员
    ifAdmin() {
      this.$axios
        .get(`/Admin/ifAdmin?id=${localStorage.getItem("id")}`)
        .then((res) => {
          if (res.data.code != 200) {
            this.$router.replace("/login");
          }
        });
    },
  },
  //离开路由
  beforeRouteLeave(to, from, next) {
    //判断是否在线
    if (this.$websocket) {
      this.$websocket.close();
      this.$websocket = null;
    }
    //放行
    next();
  },
};
</script>

<style lang="less" scoped>
.ShowButton {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100vw;
  .el-button {
    background-color: #ffffff00;
    margin: 2vh;
  }
}
.top {
  position: fixed;
  top: -100vh;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  transition: top 1s ease; /* 添加过渡效果 */
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.show-rank {
  top: 0;
}

.overlay {
  padding: 20px;
  .title {
    text-align: center;
    font-size: 2vw;
    font-weight: bold;
    color: transparent;
    background: url("@/assets/computer/index/hero.png") no-repeat center center;
    background-size: cover;
    -webkit-background-clip: text;
  }
}

.ranking-list {
  zoom: 0.9;
  /deep/ .el-descriptions__body {
    background-color: #ffffff00;
    font-weight: bolder; /*设置字体粗细*/
    cursor: pointer; /*设置光标形状*/
    color: #fefefe;
    text-shadow: 0 0 0.5em #e62b0a, 0 0 0.2em #5c5c5c;
  }
}

.main {
  width: 100vw;
  min-width: 1200px;
  height: 100vh;
  background-color: #fff;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  overflow: hidden;
  filter: drop-shadow(0 0 10px white);
  position: relative;
  .main-title {
    position: absolute;
    left: 50%;
    top: 70px;
    transform: translateX(-50%);
    font-size: 30px;
    color: rgba(255, 255, 255, 1);
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -28px;
      width: 28px;
      height: 4px;
      background: rgba(244, 54, 63, 1);
      border-radius: 2px;
    }
  }
  .stars {
    transform: perspective(500px);
    transform-style: preserve-3d;
    position: absolute;
    perspective-origin: 50% 100%;
    left: 50%;
    animation: rotate 90s infinite linear;
    bottom: -256px;
    .star {
      width: 2px;
      height: 2px;
      background: #f7f7b8;
      position: absolute;
      top: 0;
      left: 0;
      backface-visibility: hidden;
    }
    @keyframes rotate {
      0% {
        transform: perspective(400px) rotateZ(20deg) rotateX(-40deg) rotateY(0);
      }
      100% {
        transform: perspective(400px) rotateZ(20deg) rotateX(-40deg)
          rotateY(-360deg);
      }
    }
  }

  .dot-num {
    // position: relative;
    // height: 200px;
    // width: 100%;
    .dot {
      position: absolute;
      top: 0;
      display: inline-block;
      .dot-title {
        font-size: 40px;
        line-height: 37px;
        font-weight: bold;
        color: #f23340;
      }
      .dot-describe {
        margin-top: 10px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;
      }
    }
  }
}
</style>