<template>
  <div>
    <div class="box" style="display: flex">
      <div class="left">
        <el-menu
          :default-active="active"
          class="el-menu-vertical-demo"
          background-color="#273446"
          router
          text-color="#fff"
          active-text-color="#409eff"
          style="height: 100vh"
        >
          <el-menu-item index="/manage/user">
            <i class="el-icon-user"></i>
            <span slot="title">成员信息</span>
          </el-menu-item>
          <el-menu-item index="/manage/question">
            <i class="el-icon-truck"></i>
            <span slot="title">题库</span>
          </el-menu-item>
          <el-menu-item index="/manage/rank">
            <i class="el-icon-edit-outline"></i>
            <span slot="title">成绩</span>
          </el-menu-item>
          <el-menu-item index="/manage/accredit">
            <i class="el-icon-s-data"></i>
            <span slot="title">授权</span>
          </el-menu-item>
          <el-menu-item index="/manage/history">
            <i class="el-icon-edit-outline"></i>
            <span slot="title">历史榜</span>
          </el-menu-item>
          <el-menu-item index="/manage/log">
            <i class="el-icon-document"></i>
            <span slot="title">日志</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: this.$route.path,
    };
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: white;
  .left {
    width: 17vw;
    height: 100vh;
  }
  .right {
    width: 83vw;
    height: 100vh;
    overflow: auto;
  }
}
</style>