<template>
  <div style="zoom: 0.8">
    <div class="header">
      <div class="top" style="display: flex; justify-content: space-between">
        <div>
          <i class="el-icon-search"></i>
          <span> 搜索列表</span>
        </div>
        <div class="button">
          <div>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-search"
              @click="GetMemberList()"
              >搜索</el-button
            >
          </div>
          <div>
            <el-button
              size="mini"
              type=""
              icon="el-icon-refresh"
              @click="Refresh()"
              >重置</el-button
            >
          </div>
          <div>
            <el-button
              size="mini"
              type="danger"
              plain
              icon="el-icon-delete"
              @click="AdminDeleteUserAll"
              >批量删除</el-button
            >
          </div>
          <div>
            <el-button
              size="mini"
              type="warning"
              plain
              icon="el-icon-download"
              @click="ExcelDown()"
              >下载模板</el-button
            >
          </div>
          <div>
            <el-upload
              :action="action + '/Admin/AddMember'"
              :limit="1"
              :auto-upload="false"
              :on-change="onChange"
              :file-list="fileList"
              :on-success="onSuccess"
              :headers="headers"
              ref="AddMember"
            >
              <el-button
                size="mini"
                type="success"
                plain
                icon="el-icon-plus"
                @click="AddMember()"
                >批量添加</el-button
              >
            </el-upload>
          </div>
          <div>
            <el-button
              size="mini"
              icon="el-icon-key"
              style="background: black; color: white"
              @click="UpdateUserPassword()"
              >修改密码</el-button
            >
          </div>
          <div>
            <el-button
              size="mini"
              type="info"
              icon="el-icon-s-home"
              @click="ToAssociationIndex()"
              >裁判</el-button
            >
          </div>
        </div>
      </div>
      <div class="bottom" style="margin-top: 2%">
        <el-form style="margin-left: 2%" :inline="true">
          <el-form-item label="姓名">
            <div>
              <el-input
                clearable
                size="small"
                v-model="form.nickname"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="学号">
            <div>
              <el-input clearable size="small" v-model="form.stuNum"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="ID">
            <div>
              <el-input clearable size="small" v-model="form.id"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="状态">
            <div>
              <el-select clearable v-model="form.status">
                <el-option value="0"></el-option>
                <el-option value="1"></el-option>
              </el-select>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="center" style="margin-top: 2%">
      <el-table :data="user" @selection-change="onSelectionChange">
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="学号" prop="studentNumber"></el-table-column>
        <el-table-column label="姓名" prop="nickname"></el-table-column>
        <el-table-column label="专业" prop="major"></el-table-column>
        <el-table-column label="账号" prop="username"></el-table-column>
        <el-table-column label="密码" prop="password"></el-table-column>
        <el-table-column width="250" label="操作" prop="">
          <template slot-scope="scope">
            <div>
              <el-button
                style="background-color: #395667; color: white"
                size="mini"
                @click="OpenParticulars(user[scope.$index])"
                >详情</el-button
              >
              <el-button
                style="background-color: #cf3c60; color: white"
                size="mini"
                @click="DeleteMemberOne(user[scope.$index].id)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination
        :total="total"
        background=""
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        nickname: "",
        stuNum: "",
        id: "",
        status: "",
        pageNum: 1,
      }, //表单数据
      user: [], // 用户数据
      DeleteList: [], //删除列表
      id: "", //id
      total: 0, //条数
      action: this.$axios.defaults.baseURL, //服务器路径
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }, //请求头
      fileList: [], //文件路径
      DontReload: false, //是否刷新
    };
  },
  mounted() {
    this.$bus.$on("UpdateMember", () => {
      this.GetMemberList();
    });
    this.GetMemberList();
    if (this.$websocket != null && this.$websocket != undefined) {
      this.$websocket.close();
      this.$websocket = null;
    }
  },
  methods: {
    //修改密码
    UpdateUserPassword() {
      //让用户选择
      this.$msgbox({
        title: "请选择",
        showCancelButton: true,
        cancelButtonText: "练习",
        confirmButtonText: "竞赛",
        showClose: false,
        beforeClose: (action, instance, done) => {
          //自定义变量
          let status = "";
          if (action == "confirm") {
            instance.confirmButtonLoading = true;
            status = "competition";
          }
          if (action == "cancel") {
            instance.cancelButtonLoading = true;
            status = "study";
          }
          this.$axios
            .get(`/Admin/UpdateUserPassword?status=${status}`)
            .then((res) => {
              done();
              if (res.data.code == 200) {
                this.$notify.success({
                  message: "修改成功",
                  position: "top-left",
                });
                if (action == "confirm") {
                  this.ExportExcelPassword();
                }
                this.GetMemberList();
              } else {
                this.$notify.error({
                  message: "修改失败",
                  position: "top-left",
                });
              }
              instance.confirmButtonLoading = false;
              instance.cancelButtonLoading = false;
            });
        },
      });
    },
    //下载文件
    ExportExcelPassword() {
      this.$axios
        .get("/Admin/ExportExcelPassword", {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], { type: res.data.type });
          let link = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          link.href = url;
          link.download = "密码表_" + new Date().getTime() + ".xlsx";
          link.style.display = "none";
          document.body.append(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);
        });
    },
    //文件上传成功的钩子
    onSuccess(e) {
      if (e.code == 200) {
        this.$message.success({ message: "上传成功" });
        this.GetMemberList();
        this.DontReload = true;
        setTimeout(() => {
          this.DontReload = false;
        }, 0);
      }
    },
    //文件改变时的钩子
    onChange(file, fileList) {
      this.fileList = fileList;
      if (this.DontReload) {
        return;
      }
      this.$confirm(
        "文件 " + this.fileList[0].name + " 已加载完成，是否上传",
        "计算机协会",
        { type: "warning" }
      )
        .then(() => {
          this.AddMember();
        })
        .catch(() => {
          this.fileList = [];
        });
    },
    //下载模板
    ExcelDown() {
      this.$axios
        .get("/Admin/ExcelDown", {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], { type: res.data.type });
          let link = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          link.href = url;
          link.download = "template.xlsx";
          link.style.display = "none";
          document.body.append(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);
        });
    },
    //批量添加数据
    AddMember() {
      this.$refs.AddMember.submit();
    },
    //删除单条数据
    DeleteMemberOne(id) {
      this.$axios.get(`/Admin/DeleteMemberOne?id=${id}`).then((res) => {
        if (res.data.code == 200) {
          this.$notify.success({ message: "删除成功" });
          this.GetMemberList();
        } else {
          this.$notify.error({ message: "删除失败" });
        }
      });
    },
    //打开详情页并获取详情数据
    OpenParticulars(e) {
      this.$router.push({
        path: "/manage/user/update",
        query: e,
      });
    },
    //选中数组
    onSelectionChange(e) {
      this.DeleteList = e;
    },
    //批量删除
    AdminDeleteUserAll() {
      if (this.DeleteList.length == 0) {
        return;
      }
      let arr = [];
      this.DeleteList.forEach((item) => {
        arr.push(item.id);
      });
      this.$axios.post("/Admin/DeleteMemberList", arr).then((res) => {
        if (res.data.code == 200) {
          this.$notify.success({ message: "删除成功" });
          this.GetMemberList();
        } else {
          this.$notify.error({ message: "删除失败" });
        }
      });
    },
    //设置翻页
    onCurrentChange(e) {
      this.pageNum = e;
      this.GetMemberList();
    },
    //重置按钮
    Refresh() {
      (this.form = {
        nickname: "",
        stuNum: "",
        id: "",
        status: "",
        pageNum: 1,
      }), //表单数据
        this.GetMemberList();
    },
    //回到主页
    ToAssociationIndex() {
      this.$router.replace("/admin");
    },
    //获取用户条数和首页用户10条信息
    GetMemberList() {
      console.log(this.form);
      this.$axios.post(`/Admin/GetMemberList`, this.form).then(
        (res) => {
          if (res.data.code == 200) {
            this.user = res.data.data.records;
            this.total = res.data.data.total;
          }
        },
        (err) => {
          this.$notify.error({ message: "系统维护中..." });
          console.log(err);
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  padding: 1vh;
  .button {
    display: flex;
    justify-content: space-between;
  }

  .button > div {
    flex-grow: 1;
    margin: 5px;
  }
}
.center {
}
.footer {
  margin-top: 2%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2vh;
}
</style>