<template>
  <div id="app">
    <router-view></router-view>
    <SudoLove></SudoLove>
    <div id="footerMessage">
      版权信息 © 计算机协会 软件协会 © 闽IC备 2024078260 号
    </div>
  </div>
</template>

<script>
import SudoLove from "@/components/common/SudoLove.vue";
export default {
  name: "App",
  components: { SudoLove },
  mounted() {
    this.isMobile();
  },
  beforeDestroy() {},
  methods: {
    isMobile() {
      //判断是否为移动端
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      let isPhone = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(
        userAgent
      );
      if (isPhone && this.$route.path == "/home") {
        this.$router.replace("/login");
      }
    },
  },
};
</script>

<style>
#footerMessage {
  position: fixed;
  color: #bdc3c7;
  font-size: 1vh;
  right: 2vh;
  bottom: 2vh;
}
* {
  font-family: cursive;
}
#app {
  cursor: none;
  width: 100%;
  height: 100%;
  cursor: url("@/assets/common/cursor-default.9c62a210.svg") 2 2, auto;
}
</style>