<template>
  <div id="box">
    <div ref="vantaRef" style="width: 100vw; height: 100vh"></div>
    <div class="banner">
      <h1>数独大赛</h1>
      <h6>
        &ensp;&ensp;SHU&ensp;&ensp;&ensp;DU&ensp;&ensp;&ensp;&ensp;DA&ensp;&ensp;&ensp;&ensp;&ensp;SAI
      </h6>
      <p v-if="time != '2024/12/07 15:00:00'">倒计时：{{ time }}</p>
      <p v-else>比赛时间：{{ time }}</p>
      <p>组织单位：计算机协会 软件协会</p>
      <p class="ToGame" @click="ToGame">进入比赛</p>
    </div>
    <div class="rank" @click="ToRank()">岁月志</div>
  </div>
</template>

<script>
import * as THREE from "three";
import GLOBE from "vanta/src/vanta.globe";

export default {
  data() {
    return {
      time: "正在计算...",
    };
  },
  mounted() {
    this.vantaEffect = GLOBE({
      el: this.$refs.vantaRef,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color: 0x1ebeed,
      color2: 0x0,
      backgroundColor: 0x0,
    });
    if (this.$websocket != null) {
      this.$websocket.close();
      this.$websocket = null;
    }
    if (this.$message != null) {
      this.$message.close();
      this.$message = null;
    }
    //修改时间
    this.UpdateTime();
    //初始化
    this.init();
  },
  methods: {
    //获取并清空设置背景,进行初始化
    init() {
      //获取背景颜色,获取存储的数据
      let back = localStorage.getItem("back-color");
      let SudoReturn = localStorage.getItem("SudoReturn");
      //清楚原本的数据
      localStorage.clear();
      //判断是否为null
      if (SudoReturn != null) {
        localStorage.setItem("SudoReturn", SudoReturn);
      }
      //重新设置进入本地数据
      localStorage.setItem("back-color", back);
      //若连接未关闭则关闭连接
      if (this.$websocket) {
        this.$websocket.close();
        this.$websocket = null;
      }
    },
    //跳转至岁月志
    ToRank() {
      // 获取当前baseUrl
      let baseUrl = this.$axios.defaults.baseURL;
      if (!baseUrl.startsWith("https://")) {
        this.$notify.error({
          message: "当前为内网环境！",
          position: "top-left",
        });
        return;
      }
      //前往历史页面
      this.$router.push("/history");
    },
    //修改时间
    UpdateTime() {
      setInterval(() => {
        let time = new Date("2024/12/07 15:00:00"); // 目标日期
        let now = new Date(); // 当前日期和时间

        // 计算时间差值（以毫秒为单位）
        let timeDiff = Math.abs(time - now);

        if (time.getTime() < now.getTime()) {
          this.time = "2024/4/2 15:00:00";
          return;
        }

        // 将时间差转换为天数
        let days = timeDiff / (1000 * 60 * 60 * 24);
        // 将时间差转换为小时数
        let hours = (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60);
        // 将时间差转换为分钟数
        let minutes = (timeDiff % (1000 * 60 * 60)) / (1000 * 60);
        // 将时间差转换为秒数
        let seconds = (timeDiff % (1000 * 60)) / 1000;

        // 为了显示更友好的时间差，可以取绝对值
        let daysText = Math.abs(Math.floor(days));
        let hoursText = Math.floor(hours);
        let minutesText = Math.floor(minutes);
        let secondsText = Math.round(seconds);

        this.time =
          daysText +
          "天" +
          hoursText +
          "时" +
          minutesText +
          "分" +
          secondsText +
          "秒";
      }, 1000);
    },
    //进入比赛
    ToGame() {
      this.$router.push("/login");
    },
  },
  beforeDestroy() {
    if (this.vantaEffect) {
      this.vantaEffect.destroy();
    }
  },
};
</script>

<style lang="less" scoped>
#box {
  position: relative;
  background-color: white;
  .banner {
    z-index: 999;
    position: absolute;
    top: 30%;
    left: 10%;
    color: #fff;
    width: 20vw;
  }
}
h1 {
  text-align: center;
  font-size: 2vw;
  font-weight: bold;
  color: transparent;
  background: url("@/assets/computer/home/HomePage.png") no-repeat center center;
  background-size: cover;
  -webkit-background-clip: text;
}
h6 {
  text-align: center;
  font-size: 1vw;
  font-weight: bold;
  color: transparent;
  background: url("@/assets/computer/home/HomePage.png") no-repeat center center;
  background-size: cover;
  -webkit-background-clip: text;
}
p {
  margin-top: 4vw;
  font-weight: bold;
  font-size: 1vw;
  color: transparent;
  text-align: center;
  background: url("@/assets/computer/home/HomePage.png") no-repeat center center;
  background-size: cover;
  -webkit-background-clip: text;
}
.ToGame {
  background: url("@/assets/computer/home/homepage3.png") no-repeat center
    center;
  margin-top: 4vw;
  font-weight: bold;
  font-size: 2vw;
  color: transparent;
  text-align: center;
  background-size: cover;
  -webkit-background-clip: text;
  cursor: pointer;
}
.rank {
  position: fixed;
  top: 2vh;
  right: 2vw;
  background: url("@/assets/computer/home/SudoRankBack.png") no-repeat center
    center;
  font-weight: bold;
  font-size: 2vw;
  color: transparent;
  text-align: center;
  background-size: cover;
  -webkit-background-clip: text;
  cursor: pointer;
  width: 10vw;
}
</style>
