<template>
  <div style="zoom: 0.8">
    <div class="acredit-button">
      <el-dropdown trigger="click" @command="handleCommand">
        <el-button>选择授权</el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">允许授权</el-dropdown-item>
          <el-dropdown-item command="0">禁止授权</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button style="margin-left: 1vw" @click="add">新增</el-button>
    </div>
    <div class="center" style="margin-top: 2%">
      <el-table :data="question" :class="{ changeColor: question.id < 9 }">
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="编号" prop="accreditId"></el-table-column>
        <el-table-column label="用户" prop="accreditUserId"></el-table-column>
        <el-table-column label="次数" prop="accreditNum"></el-table-column>
        <el-table-column width="250" label="操作" prop="">
          <template slot-scope="scope">
            <div>
              <el-button
                style="background-color: #395667; color: white"
                size="mini"
                @click="updateAccreditNum(question[scope.$index].accreditId)"
                >修改</el-button
              >
              <el-button
                style="background-color: #395667; color: white"
                size="mini"
                @click="deleteAccreit(question[scope.$index].accreditId)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      question: [],
      id: "",
    };
  },
  mounted() {
    this.GetMemberList();
  },
  methods: {
    // 修改授权次数
    updateAccreditNum(id) {
      this.$prompt("请输入授权码", "提示").then((md) => {
        if (md.value == "") {
          return;
        }
        this.$prompt("请输入授权次数", "授权提示").then((value) => {
          // 判断是否为空
          if (value.value == "") {
            this.$message.error({ message: "次数为空！" });
            return;
          }
          const accredit = {
            password: md.value,
            num: value.value,
            id: id,
          };
          // 发起请求
          this.$axios.post("/Admin/updateAccreditNum", accredit).then((res) => {
            if (res.data.code == 200) {
              this.$notify.success({ message: "授权成功" });
              this.GetMemberList();
            } else {
              this.$notify.error({ message: res.data.msg });
            }
          });
        });
      });
    },
    // 进行授权操作
    handleCommand(accredit) {
      // 提醒用户输入授权密码
      this.$prompt("请输入授权码", "提示").then((value) => {
        // 授权为空
        if (value.value == "") {
          return;
        }
        const accreditObj = {
          password: value.value,
          accredit: accredit,
        };
        // 开始授权
        this.$axios.post(`/Admin/accreditUpdate`, accreditObj).then((res) => {
          if (res.data.code == 200) {
            this.$notify.success({ message: "修改成功" });
            this.GetMemberList();
          } else {
            this.$notify.error({ message: res.data.msg });
          }
        });
      });
    },
    // 新增操作
    add() {
      this.$prompt("请输入授权码", "提示").then((md) => {
        if (md.value == "") {
          return;
        }
        this.$prompt("请输入用户ID", "授权提示").then((value) => {
          // 判断是否为空
          if (value.value == "") {
            this.$message.error({ message: "ID为空！" });
            return;
          }
          const accredit = {
            password: md.value,
            id: value.value,
          };
          // 发起请求
          this.$axios.post("/Admin/accredit", accredit).then((res) => {
            if (res.data.code == 200) {
              this.$notify.success({ message: "授权成功" });
              this.GetMemberList();
            } else {
              this.$notify.error({ message: res.data.msg });
            }
          });
        });
      });
    },
    //删除单条数据
    deleteAccreit(id) {
      // 提醒用户输入授权密码
      this.$prompt("请输入授权码", "提示").then((value) => {
        // 授权为空
        if (value.value == "") {
          return;
        }
        const accreditObj = {
          password: value.value,
          id: id,
        };
        // 开始授权
        this.$axios.post(`/Admin/accreditDelete`, accreditObj).then((res) => {
          if (res.data.code == 200) {
            this.$notify.success({ message: "删除成功" });
            this.GetMemberList();
          } else {
            this.$notify.error({ message: res.data.msg });
          }
        });
      });
    },
    //获取用户条数和首页用户10条信息
    GetMemberList() {
      this.$axios.get(`/Admin/accreditList`).then(
        (res) => {
          if (res.data.code == 200) {
            this.question = res.data.data;
          }
        },
        (err) => {
          this.$notify.error({ message: "系统维护中..." });
          console.log(err);
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.acredit-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 1vw;
}
.center {
  &.changeColor {
    color: red;
  }
}
.footer {
  margin-top: 2%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2vh;
}
</style>