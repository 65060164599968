<template>
  <div style="zoom: 0.8">
    <div class="top" @keyup.enter="SearchUser()">
      <div>
        搜索：
        <el-input v-model="search" placeholder="请输入用户id">
          <el-button icon="el-icon-search" @click="SearchUser()" slot="append"
            >搜索</el-button
          >
        </el-input>
      </div>
      <div>
        <el-button type="danger" @click="DeleteAllScore">清空成绩表</el-button>
        <el-button type="success" @click="ExportExcel">导出Excel表格</el-button>
      </div>
    </div>
    <div class="center" style="margin-top: 2%">
      <el-table :data="rankings">
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="编号" prop="id"></el-table-column>
        <el-table-column label="用户id" prop="userId"></el-table-column>
        <el-table-column
          label="通关次数"
          prop="successNumber"
        ></el-table-column>
        <el-table-column label="分数" prop="score"></el-table-column>
        <el-table-column label="时间" prop="time"></el-table-column>
        <el-table-column label="开始时间" prop="startTime"></el-table-column>
        <el-table-column width="250" label="操作" prop="">
          <template slot-scope="scope">
            <div>
              <el-button
                style="background-color: #395667; color: white"
                size="mini"
                @click="UpdateQuestion(rankings[scope.$index])"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="DeleteScore(rankings[scope.$index].id)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background :total="total"> </el-pagination>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rankings: [], //存放数据的数组
      id: "", //获取用户id
      skip: 1, //翻页
      search: "", //搜索用户名字
      total: 0, //翻页条数
    };
  },
  mounted() {
    this.$bus.$on("UpdateRank", () => {
      this.GetMemberList();
    });
    this.GetMemberList();
  },
  methods: {
    // 删除一个人的成绩内容
    DeleteScore(id) {
      this.$confirm("是否清空当前用户成绩内容", "提示")
        .then(() => {
          this.$axios.get(`/Admin/DeleteScore?id=${id}`).then((res) => {
            if (res.data.code == 200) {
              this.$notify.success({ message: "删除成功" });
              this.GetMemberList();
            } else {
              this.$notify.error({ message: "删除失败" });
            }
          });
        })
        .catch(() => {});
    },
    // 清空成绩表
    DeleteAllScore() {
      this.$confirm("是否清空当前所有用户成绩内容", "提示")
        .then(() => {
          this.$axios.get("/Admin/DeleteAllScore").then((res) => {
            if (res.data.code == 200) {
              this.$notify.success({ message: "删除成功" });
              this.GetMemberList();
            } else {
              this.$notify.error({ message: "删除失败" });
            }
          });
        })
        .catch(() => {});
    },
    //ExportExcel导出表格数据
    ExportExcel() {
      this.$axios
        .get("/Admin/ExportExcel", {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], { type: res.data.type });
          let link = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          link.href = url;
          link.download = "成绩表_" + new Date().getTime() + ".xlsx";
          link.style.display = "none";
          document.body.append(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);
        });
    },
    //翻页操作
    ChangeSkip(e) {
      this.skip = e;
      this.GetMemberList();
    },
    //搜索用户
    SearchUser() {
      this.GetMemberList();
    },
    //删除单条数据
    DeleteMemberOne(id) {
      this.$axios.get(`/Admin/DeleteMemberOne?id=${id}`).then((res) => {
        if (res.data.code == 200) {
          this.$notify.success({ message: "删除成功" });
          this.GetMemberList();
        } else {
          this.$notify.error({ message: "删除失败" });
        }
      });
    },
    //打开详情页并获取详情数据
    UpdateQuestion(ranking) {
      ranking = JSON.stringify(ranking);
      this.$router.push({
        path: "/manage/rank/update",
        query: {
          ranking: ranking,
        },
      });
    },
    //获取用户条数和首页用户10条信息
    GetMemberList() {
      this.$axios
        .get(`/Admin/GetUserRank?skip=${this.skip}&search=${this.search}`)
        .then((res) => {
          if (res.data.code == 200) {
            this.rankings = res.data.data.records;
            this.total = res.data.data.total;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  margin: 2vh;
  display: flex;
  justify-content: space-between;
  .el-input {
    width: 30vw;
  }
}
.center {
}
.footer {
  margin-top: 2%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2vh;
}
.el-pagination {
  margin: 2vw;
  padding: 2vw;
}
</style>