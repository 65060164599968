// 发送请求日志记录
export function logRequestRecord(config) {
  // 判断是否为内网环境
  if (config.baseURL.startsWith("https://")) {
    return;
  }
  // 判断方法为option返回
  if (config.method == "OPTIONS") {
    return;
  }
  // 从本地读取日志
  let log = localStorage.getItem("log");
  if (log == null || log == undefined || log == "") {
    log = [];
  } else {
    log = JSON.parse(log);
    // 判断是否为数组
    if (!Array.isArray(log)) {
      log = [];
    }
  }
  // 判断数组长度，如果超过300条则删除第一条
  if (log.length > 300) {
    log.shift();
  }
  // 创建一个对象，存储消息
  let message = {
    type: "发送",
    method: config.method,
    url: config.url,
    data: config.data,
    timeout: config.timeout,
    now: new Date().toLocaleString(),
  };
  // 存入数组
  log.push(message);
  // 存入本地
  localStorage.setItem("log", JSON.stringify(log));
}

// 请求结果日志存储
export function logResponseRecord(res) {
  // 判断是否为内网环境
  if (res.config.baseURL.startsWith("https://")) {
    return;
  }
  // 从本地读取日志
  let log = localStorage.getItem("log");
  if (log == null || log == undefined || log == "") {
    log = [];
  } else {
    log = JSON.parse(log);
    // 判断是否为数组
    if (!Array.isArray(log)) {
      log = [];
    }
  }
  // 判断数组长度，如果超过300条则删除第一条
  if (log.length > 300) {
    log.shift();
  }
  // 创建一个对象，存储消息
  let message = {
    type: "接收",
    method: res.config.method,
    url: res.config.url,
    data: res.data,
    status: res.status,
    now: new Date().toLocaleString(),
  };
  // 存入数组
  log.push(message);
  // 存入本地
  localStorage.setItem("log", JSON.stringify(log));
}

// 读取消息日志
export function logRead() {
  // 从本地读取日志
  let log = localStorage.getItem("log");
  if (log == null || log == undefined || log == "") {
    log = [];
  } else {
    log = JSON.parse(log);
    // 判断是否为数组
    if (!Array.isArray(log)) {
      log = [];
    }
  }
  // 返回数组信息
  return log;
}
