import axios from "axios";
import { Message } from "element-ui";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
//引入路由
import router from "@/router";
//引入日志
import { logRequestRecord, logResponseRecord } from "@/utils/log.js";

//定义一个变量  防止提醒两次当ip限制时候
let isFrequencyWarningShown = false;

//创建一个axios实例
const server = axios.create({
  history: true,
  // baseURL: "http://192.168.231.93:9999", //本地服务器
  baseURL: "https://sudoku.yusoft.fun/api", //服务器
  // webURL: "http://192.168.1.246:8807/static", //本地服务器
  webURL: "https://sudoku.yusoft.fun/static", //本地服务器
  withCredentials: true, //开启设置带上请求头
  timeout: 15000, //设置请求时长
});

//请求拦截器
server.interceptors.request.use((config) => {
  let url = config.url;
  if (
    url != "/user/getUserHero" &&
    !url.startsWith("/Admin/GetQuestionAnswer")
  ) {
    // //请求发起的时候设置加载状态
    NProgress.start();
    //如果长时间未响应则结束加载状态
    setTimeout(() => {
      NProgress.done();
    }, 10000);
  }

  //获取请求头
  const token = localStorage.getItem("token");
  //若不为null则带上请求头
  if (token != null) {
    config.headers.Authorization = "Bearer " + token;
  }

  // 请求之前进行本地日志存储
  logRequestRecord(config);

  return config;
});

//响应拦截器
server.interceptors.response.use((res) => {
  // 请求之后进行本地日志存储
  logResponseRecord(res);
  //统一处理后端响应状态为401结果
  if (res.data == "410") {
    Message.error({ message: "认证失败，请重新认证" });
    localStorage.setItem("leave", "leave");
    localStorage.removeItem("token");
    if (router.currentRoute.path != "/login") {
      router.replace("/login");
    }
    return;
  }
  //统一处理后端响应状态为101结果
  if (res.data == "101" && !isFrequencyWarningShown) {
    isFrequencyWarningShown = true;
    Message.error({ message: "请求次数过于频繁，请稍后" });
    setTimeout(() => {
      isFrequencyWarningShown = false;
    }, 2000);
    return res;
  }
  //请求结束关闭加载
  NProgress.done();
  return res;
});

//暴漏实例
export default server;
