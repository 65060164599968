<template>
  <div>
    <socket></socket>
    <div class="overlay">
      <!-- 排行榜内容 -->
      <div class="title">英雄榜</div>
      <!-- 这里可以根据你的需求添加排行榜的具体内容 -->
      <div class="box">
        <div class="ranking-list">
          <!-- 排行榜项 -->
          <el-descriptions
            :colon="false"
            :column="6"
            style="margin-top: 2vh; margin-left: 2vw"
          >
            <el-descriptions-item>排名</el-descriptions-item>
            <el-descriptions-item>专业</el-descriptions-item>
            <el-descriptions-item>姓名</el-descriptions-item>
            <el-descriptions-item>通关</el-descriptions-item>
            <el-descriptions-item>得分</el-descriptions-item>
            <el-descriptions-item>总耗时</el-descriptions-item>
          </el-descriptions>
          <div v-for="(item, index) in rankingData" :key="index">
            <el-descriptions
              :colon="false"
              :column="6"
              style="margin-top: 2vh; margin-left: 2vw"
              class="item"
            >
              <el-descriptions-item>{{ index + 1 }}</el-descriptions-item>
              <el-descriptions-item>{{ item.major }}</el-descriptions-item>
              <el-descriptions-item>{{ item.nickname }}</el-descriptions-item>
              <el-descriptions-item>{{
                item.successNumber
              }}</el-descriptions-item>
              <el-descriptions-item>{{ item.score }}</el-descriptions-item>
              <el-descriptions-item>{{ item.time }}</el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//导入socket连接
import socket from "@/components/socket/WebSocket.vue";
export default {
  components: { socket },
  data() {
    return {
      rankingData: [], //排名数据
    };
  },
  mounted() {
    //获取排行榜数据信息
    this.GetRankingData();
  },
  methods: {
    //获取排行榜数据
    GetRankingData() {
      //发起请求获取排行榜数据信息
      this.GetRankingDataList();
      //发起请求获取排行榜数据信息
      this.$bus.$on("getRanking", () => {
        //发起请求获取数据
        this.GetRankingDataList();
      });
    },
    //发起请求获取排行榜数据
    GetRankingDataList() {
      //发起请求
      this.$axios.get("/user/getUserHero").then((res) => {
        //循环排行榜数组,如果里面的id都没有自己的id,则直接顶出去用户
        this.rankingData = res.data.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.overlay {
  padding: 20px;
  padding-bottom: 100px;
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
  }
}

.box {
  display: flex;
  justify-content: center;
  margin: auto;

  margin-top: 30px;
  align-items: center;
  .ranking-list {
    zoom: 0.9;
    /deep/ .el-descriptions__body {
      background-color: #ffffff;
      font-weight: bolder; /*设置字体粗细*/
      color: black;
    }
  }
}
</style>