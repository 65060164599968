<template>
  <div>
    <div class="box" v-if="histories.length != 0">
      <div class="title">岁月志</div>
      <div class="card">
        <CardHistoryVue
          v-for="(item, index) in histories"
          :key="index"
          :item="item"
        ></CardHistoryVue>
      </div>
    </div>
  </div>
</template>

<script>
import CardHistoryVue from "@/components/history/CardHistory.vue";
export default {
  components: { CardHistoryVue },
  data() {
    return {
      histories: [],
    };
  },
  mounted() {
    //查询历史英雄榜数据信息
    this.GetHistoryList();
  },
  methods: {
    // 查询历史英雄榜数据信息
    GetHistoryList() {
      this.$axios.get("/history/list").then((res) => {
        this.histories = res.data.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .title {
    font-weight: 600;
    text-align: center;
    margin-top: 30px;
    font-size: 30px;
  }
  .card {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
</style>