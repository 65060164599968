<template>
  <div>
    <div class="chat-box" v-show="showChat" ref="scrollableDiv">
      <div class="message" v-for="(item, index) in messageList" :key="index">
        <span v-if="item.nickname == '系统消息'" style="color: red">
          {{ item.nickname }}： {{ item.message }}</span
        >
        <span v-if="item.nickname != '系统消息'"> {{ item.nickname }}： </span>
        <img
          src="@/assets/message/1.jpg"
          v-if="item.message == '1'"
          alt=""
        />
        <img
          src="@/assets/message/2.jpg"
          v-if="item.message == '2'"
          alt=""
        />
        <img
          src="@/assets/message/3.jpg"
          v-if="item.message == '3'"
          alt=""
        />
        <img
          src="@/assets/message/4.jpg"
          v-if="item.message == '4'"
          alt=""
        />
        <img
          src="@/assets/message/5.jpg"
          v-if="item.message == '5'"
          alt=""
        />
        <img
          src="@/assets/message/6.jpg"
          v-if="item.message == '6'"
          alt=""
        />
        <img
          src="@/assets/message/7.jpg"
          v-if="item.message == '7'"
          alt=""
        />
        <img
          src="@/assets/message/8.jpg"
          v-if="item.message == '8'"
          alt=""
        />
        <img
          src="@/assets/message/9.jpg"
          v-if="item.message == '9'"
          alt=""
        />
        <img
          src="@/assets/message/10.jpg"
          v-if="item.message == '10'"
          alt=""
        />
        <img
          src="@/assets/message/11.jpg"
          v-if="item.message == '11'"
          alt=""
        />
        <img
          src="@/assets/message/12.jpg"
          v-if="item.message == '12'"
          alt=""
        />
        <img
          src="@/assets/message/13.jpg"
          v-if="item.message == '13'"
          alt=""
        />
        <img
          src="@/assets/message/14.jpg"
          v-if="item.message == '14'"
          alt=""
        />
        <img
          src="@/assets/message/15.jpg"
          v-if="item.message == '15'"
          alt=""
        />
        <img
          src="@/assets/message/16.jpg"
          v-if="item.message == '16'"
          alt=""
        />
        <img
          src="@/assets/message/17.jpg"
          v-if="item.message == '17'"
          alt=""
        />
        <img
          src="@/assets/message/18.jpg"
          v-if="item.message == '18'"
          alt=""
        />
        <img
          src="@/assets/message/19.jpg"
          v-if="item.message == '19'"
          alt=""
        />
        <img
          src="@/assets/message/20.jpg"
          v-if="item.message == '20'"
          alt=""
        />
        <img
          src="@/assets/message/21.jpg"
          v-if="item.message == '21'"
          alt=""
        />
        <img
          src="@/assets/message/22.jpg"
          v-if="item.message == '22'"
          alt=""
        />
        <img
          src="@/assets/message/23.jpg"
          v-if="item.message == '23'"
          alt=""
        />
      </div>
      <div class="small-box" style="padding: 5vh"></div>
      <div class="input">
        <el-popover placement="left" width="400" trigger="click">
          <img
            style="width: 5vw; height: 5vw; margin: 0 1vw; cursor: pointer"
            :src="item.img"
            v-for="(item, index) in imgList"
            :key="index"
            alt=""
            @click="sendMessage(item.name)"
          />
          <el-tooltip slot="reference" content="打开表情包">
            <el-button icon="el-icon-upload" circle></el-button>
          </el-tooltip>
          <el-tooltip slot="reference" content="重新刷新消息连接">
            <el-button
              icon="el-icon-refresh"
              v-if="$message == null || $message == undefined"
              circle
              @click="MessageSocket"
            ></el-button>
          </el-tooltip>
          <el-tooltip slot="reference" content="关闭消息连接，拒绝接收">
            <el-button
              icon="el-icon-arrow-left"
              @click="closeChat"
              circle
            ></el-button>
          </el-tooltip>
        </el-popover>
      </div>
    </div>
    <div class="chat-box" v-show="!showChat">
      <el-tooltip content="打开消息连接">
        <el-button
          icon="el-icon-arrow-right"
          circle
          @click="openChat"
        ></el-button>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  data() {
    return {
      showChat: false, //是否展示聊天
      id: localStorage.getItem("id"), //用户的id信息
      baseURL: this.$axios.defaults.baseURL, //api连接路径信息
      messageList: [], //消息数组
      imposeTime: false, //限制发送速度
      imgList: [
        {
          name: "1",
          img: require("@/assets/message/1.jpg"),
        },
        {
          name: "2",
          img: require("@/assets/message/2.jpg"),
        },
        {
          name: "3",
          img: require("@/assets/message/3.jpg"),
        },
        {
          name: "4",
          img: require("@/assets/message/4.jpg"),
        },
        {
          name: "5",
          img: require("@/assets/message/5.jpg"),
        },
        {
          name: "6",
          img: require("@/assets/message/6.jpg"),
        },
        {
          name: "7",
          img: require("@/assets/message/7.jpg"),
        },
        {
          name: "8",
          img: require("@/assets/message/8.jpg"),
        },
        {
          name: "9",
          img: require("@/assets/message/9.jpg"),
        },
        {
          name: "10",
          img: require("@/assets/message/10.jpg"),
        },
        {
          name: "11",
          img: require("@/assets/message/11.jpg"),
        },
        {
          name: "12",
          img: require("@/assets/message/12.jpg"),
        },
        {
          name: "13",
          img: require("@/assets/message/13.jpg"),
        },
        {
          name: "14",
          img: require("@/assets/message/14.jpg"),
        },
        {
          name: "15",
          img: require("@/assets/message/15.jpg"),
        },
        {
          name: "16",
          img: require("@/assets/message/16.jpg"),
        },
        {
          name: "17",
          img: require("@/assets/message/17.jpg"),
        },
        {
          name: "18",
          img: require("@/assets/message/18.jpg"),
        },
        {
          name: "19",
          img: require("@/assets/message/19.jpg"),
        },
        {
          name: "20",
          img: require("@/assets/message/20.jpg"),
        },
        {
          name: "21",
          img: require("@/assets/message/21.jpg"),
        },
        {
          name: "22",
          img: require("@/assets/message/22.jpg"),
        },
        {
          name: "23",
          img: require("@/assets/message/23.jpg"),
        },
      ], // 图片数据
    };
  },
  mounted() {},
  methods: {
    // 发送消息
    sendMessage(item) {
      // 判断是否速率过快
      if (this.imposeTime) {
        const message = {
          nickname: "系统消息",
          message: "发送速度过快！",
        };
        this.messageList.push(message);
        return;
      }
      // 判断连接是否异常
      if (this.$message == null || this.$message == undefined) {
        // 创建一个数组
        const message = {
          nickname: "系统消息",
          message: "连接异常，无法发送！",
        };
        this.messageList.push(message);
        return;
      }
      // 发送消息
      this.$message.send(item);
      this.imposeTime = true;
      setTimeout(() => {
        this.imposeTime = false;
      }, 3000);
    },
    // 关闭聊天操作
    closeChat() {
      this.showChat = false;
      // 判断是否已连接
      if (this.$message != null && this.$message != undefined) {
        this.$message.close();
      }
    },
    // 开启聊天操作
    openChat() {
      this.showChat = true;
      // 等待事件最底部
      this.$nextTick(() => {
        this.MessageSocket();
        this.scrollToBottom();
      });
    },
    // 设置到元素最底部
    scrollToBottom() {
      const element = this.$refs.scrollableDiv;
      if (element != null && element != undefined && element != "") {
        element.scrollTo({
          top: element.scrollHeight,
          behavior: "smooth",
        });
      }
    },
    //建立websocket连接
    MessageSocket() {
      // 判断是否已经连接，未连接则刷新连接
      //创建一个长连接
      let websocket = null;
      //判断是否可以连接
      let url = this.$axios.defaults.baseURL;
      if ("WebSocket" in window) {
        if (url.startsWith("http://")) {
          url = url.slice(7);
          websocket = new WebSocket(`ws://${url}/ws/message/${this.id}`);
        }
        if (url.startsWith("https://")) {
          url = url.slice(8);
          websocket = new WebSocket(`wss://${url}/ws/message/${this.id}`);
        }
      } else {
        alert("此网站不支持WebSocket连接，但不影响竞赛正常进行！");
      }
      websocket.onopen = () => {
        //用户成功进行连接,连接后进行原型绑定操作
        const message = {
          message: "成功建立连接！",
          nickname: "系统消息",
        };
        // 加入数组
        this.messageList.push(message);
        //进行原型绑定操作
        Vue.prototype.$message = websocket;
        //开始发送心跳包维持连接
        this.SendHeatMessage();
        this.scrollToBottom();
      };
      websocket.onmessage = (e) => {
        // 收到消息处理
        this.receiveMessage(e);
      };
      // 关闭连接
      websocket.onclose = async (err) => {
        //连接关闭,提醒用户联系管理员
        console.log(err);
        //判断是否在主页面
        if (this.$route.path == "/index") {
          const message = {
            nickname: "系统消息",
            message: "消息连接关闭！",
          };
          this.messageList.push(message);
          this.scrollToBottom();
        }
        this.$message = null;
      };
      //连接发生错误
      websocket.onerror = async (err) => {
        //连接异常,提醒用户联系管理员
        console.log(err);
        const message = {
          nickname: "系统消息",
          message: "消息连接异常！",
        };
        this.messageList.push(message);
        this.scrollToBottom();
        this.$message = null;
      };
    },
    //开始发送心跳包维持连接
    async SendHeatMessage() {
      //判断是否为null
      if (this.$message == null || this.$message == undefined) {
        return;
      }
      this.$message.send("ping");
      //每隔三秒重新发送
      await this.sleep();
      this.SendHeatMessage();
    },
    //设置停顿时间
    async sleep() {
      return new Promise((resolve) => {
        setTimeout(resolve, 30000);
      });
    },
    // 收到消息处理
    receiveMessage(e) {
      // 将数据进行解析
      const data = JSON.parse(e.data);
      if (data.message == "pong") {
        console.log("收到消息pong", "拒绝处理！");
        return;
      }
      const message = {
        message: data.message,
        nickname: data.nickname,
      };
      // 加入数组
      this.messageList.push(message);
      this.scrollToBottom();
    },
  },
};
</script>

<style lang="less" scoped>
.chat-box {
  position: fixed;
  z-index: 999;
  font-size: 0.7vw;
  left: 1vw;
  top: 10vw;
  height: 50vh;
  overflow: auto;
  width: 19vw;
  .message {
    display: flex;
    padding: 1vh;
    img {
      width: 7vw;
      height: 6vw;
    }
  }
  .input {
    bottom: 0;
    left: 0;
    z-index: 9999;
    position: sticky;
    /deep/ .el-input__inner {
      background-color: #f5f7fa !important;
      color: black;
    }
    /deep/ .el-input__inner::placeholder {
      color: black;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>