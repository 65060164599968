import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "./axios";
import router from "./router";
import Router from "vue-router";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.snow.css";

//全局注册
Vue.use(ElementUI);
Vue.use(Router);
Vue.use(VueQuillEditor);

//绑定原型
Vue.prototype.$axios = axios;

//关闭提示
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
}).$mount("#app");
