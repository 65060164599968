<template>
  <div id="big-box">
    <div class="header">
      <div class="form">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="日志文件">
            <el-cascader
              v-model="form.type"
              :options="listFileNames"
            ></el-cascader>
          </el-form-item>
          <el-form-item>
            <el-button plain type="primary" @click="getList">查询</el-button>
          </el-form-item>
          <el-form-item>
            <span style="cursor: pointer" @click="logTip()">日志提示</span>
            <span
              style="margin-left: 30px; color: #999999; cursor: pointer"
              v-show="data?.length != 0"
              @click="logSearchTip"
              >通过Ctrl+F键快速搜索定位</span
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="center">
      <div class="title">
        <div class="sub-title">日志列表</div>
        <div class="button">
          <el-button palin @click="getList" class="button-content"
            >刷新</el-button
          >
        </div>
      </div>
      <div class="content" v-if="data?.length != 0 && !isRequest">
        <div
          v-for="(item, index) in formattedData"
          :key="index"
          class="item"
          :style="getItemStyle(item)"
        >
          <span :style="{ color: item.style }">{{ item.text }}</span>
        </div>
      </div>
      <div class="empty" v-else-if="data?.length == 0 && isRequest">
        请先选择日志文件时间
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      data: [], //数据
      form: { pageNum: 1, type: null }, // 请求
      listFileNames: [], //日志文件时间
      isRequest: true, // 是否正在请求
    };
  },
  computed: {
    // 计算
    formattedData() {
      let start = false;
      return this.data
        .map((item) => {
          if (
            item.includes(
              "====================HTTP CALL START===================="
            )
          ) {
            start = true;
            return { text: item, style: "red", start };
          }
          if (
            item.includes(
              "====================HTTP CALL FINISH===================="
            )
          ) {
            start = false;
            return { text: item, style: "red", end: start };
          }
          if (start) {
            return { text: item, style: "black" };
          } else {
            return { text: item, style: "green" };
          }
        })
        .filter((item) => !item.start || !item.end);
    },
  },
  mounted() {
    // 获取日志文件夹下的文件名称
    this.logFiles();
  },
  methods: {
    // 日志提示
    logTip() {
      this.$alert(
        "日志保存时间默认3天，每个日志文件默认500KB，每个文件默认保存3天，3天后自动删除，若需修改配置请联系技术人员",
        "日志提示"
      )
        .then(() => {})
        .catch(() => {});
    },
    // 日志提示
    logSearchTip() {
      this.$alert(
        "可通过Ctrl+F快速查询访问，从接口中获取用户token信息，通过缓存列表登录token＋指定key快速查询用户",
        "操作提示"
      )
        .then(() => {})
        .catch(() => {});
    },
    // 获取日志文件夹下的所有文件名称
    logFiles() {
      this.$axios.get("/Admin/logFiles").then((res) => {
        if (res.data.code == 200) {
          let listFileNames = res.data.data;
          this.listFileNames = this.transformData(listFileNames);
          return;
        }
        // 请求失败
        this.$message.error({ message: res.data.msg });
      });
    },
    // 进行解构
    transformData(listFileNames) {
      const data = [];
      const map = new Map();

      listFileNames.forEach((fileName) => {
        // 提取日期前缀
        const datePrefix = fileName.substring(0, 10);

        // 如果 map 中还没有该日期的条目，则创建一个
        if (!map.has(datePrefix)) {
          map.set(datePrefix, {
            value: datePrefix,
            label: new Date(datePrefix).toLocaleDateString("zh-CN", {
              year: "numeric",
              month: "long",
              day: "numeric",
            }),
            children: [],
          });
        }
        // 获取对应日期的条目
        const dateEntry = map.get(datePrefix);
        // 文件名（去除日期前缀）
        const label = fileName.substring(11);
        // 将文件名添加到对应日期的 children 中
        dateEntry.children.push({
          value: label,
          label: label,
        });
      });

      // 将 map 转换为数组，并返回
      map.forEach((entry) => {
        data.push(entry);
      });
      return data;
    },
    // 设置颜色
    getItemStyle(item) {
      if (item.style === "green") {
        return { color: "green" };
      }
    },
    // 请求数据
    getList() {
      // 判断是否为空
      if (this.form.type == null || this.form.type == "") {
        this.$message.error({ message: "请先选择日志文件时间" });
        return;
      }
      // 设置请求
      this.isRequest = true;
      // 封装表单数据
      const form = {
        pageNum: this.form.pageNum,
        type: this.form.type[0] + "-" + this.form.type[1],
      };
      // 请求数据
      this.$axios.post(`/Admin/log`, form).then((res) => {
        this.isRequest = false;
        // 是否成功
        if (res.data.code == 200) {
          this.data = res.data.data;
          this.total = this.data.length;
          return;
        }
        // 请求失败
        this.$message.error({ message: res.data.msg });
      });
    },
  },
};
</script>

<style lang="less" scoped>
#big-box {
  .header {
    padding: 10px 20px;
  }
  .center {
    padding: 10px 20px;
    .title {
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
    }
    .button {
      display: flex;
      .button-content {
        margin-left: 10px;
      }
    }
    .content {
      margin-left: 20px;
      .item {
        padding: 1vh;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }
}
.empty {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.loading {
  margin-top: -120px;
}
</style>