<template>
  <div>
    <div class="title">英雄榜</div>
    <div class="rank-box" v-if="detail != null">
      <div class="rank-six">
        <div class="one">
          <div class="item" v-for="(item, index) in 1" :key="index">
            <el-image
              style="width: 120px; height: 160px"
              :src="webURL + detail.one"
              v-if="detail.one != null && detail.one != ''"
              :preview-src-list="[webURL + detail.one]"
            >
            </el-image>
            <el-image
              style="width: 120px; height: 160px"
              :src="require('@/assets/rank/empty.png')"
              v-else
              :preview-src-list="[require('@/assets/rank/empty.png')]"
            >
            </el-image>
            <div class="user">
              <div class="user-nickname">
                {{ rankingData[0].major }} {{ rankingData[0].name }}
              </div>
              <img src="@/assets/rank/first.png" alt="" />
            </div>
          </div>
        </div>
        <div class="two-three">
          <div class="item" v-for="(item, index) in 2" :key="index">
            <el-image
              style="width: 120px; height: 160px"
              :src="webURL + detail.two"
              v-if="index == 0 && detail.two != null && detail.two != ''"
              :preview-src-list="[webURL + detail.two]"
            >
            </el-image>
            <el-image
              style="width: 120px; height: 160px"
              :src="require('@/assets/rank/empty.png')"
              v-if="index == 0 && (detail.two == null || detail.two == '')"
              :preview-src-list="[require('@/assets/rank/empty.png')]"
            >
            </el-image>
            <el-image
              style="width: 120px; height: 160px"
              :src="webURL + detail.three"
              v-if="index == 1 && detail.three != null && detail.three != ''"
              :preview-src-list="[webURL + detail.three]"
            >
            </el-image>
            <el-image
              style="width: 120px; height: 160px"
              :src="require('@/assets/rank/empty.png')"
              v-if="(index == 1 && detail.three == null) || detail.three == ''"
              :preview-src-list="[require('@/assets/rank/empty.png')]"
            >
            </el-image>
            <div class="user">
              <div class="user-nickname">
                {{ rankingData[index + 1].major }}
                {{ rankingData[index + 1].name }}
              </div>
              <img src="@/assets/rank/second.png" alt="" />
            </div>
          </div>
        </div>
        <div class="four-six">
          <div class="item" v-for="(item, index) in 3" :key="index">
            <el-image
              style="width: 120px; height: 160px"
              :src="webURL + detail.four"
              v-if="index == 0 && detail.four != null && detail.four != ''"
              :preview-src-list="[webURL + detail.four]"
            >
            </el-image>
            <el-image
              style="width: 120px; height: 160px"
              :src="require('@/assets/rank/empty.png')"
              v-if="(index == 0 && detail.four == null) || detail.four == ''"
              :preview-src-list="[require('@/assets/rank/empty.png')]"
            >
            </el-image>
            <el-image
              style="width: 120px; height: 160px"
              :src="webURL + detail.five"
              v-if="index == 1 && detail.five != null && detail.five != ''"
              :preview-src-list="[webURL + detail.five]"
            >
            </el-image>
            <el-image
              style="width: 120px; height: 160px"
              :src="require('@/assets/rank/empty.png')"
              v-if="index == 1 && (detail.five == null || detail.five == '')"
              :preview-src-list="[require('@/assets/rank/empty.png')]"
            >
            </el-image>
            <el-image
              style="width: 120px; height: 160px"
              :src="webURL + detail.six"
              v-if="index == 2 && detail.six != null && detail.six != ''"
              :preview-src-list="[webURL + detail.six]"
            >
            </el-image>
            <el-image
              style="width: 120px; height: 160px"
              :src="require('@/assets/rank/empty.png')"
              v-if="index == 2 && (detail.six == null || detail.six == '')"
              :preview-src-list="[require('@/assets/rank/empty.png')]"
            >
            </el-image>
            <div class="user">
              <div class="user-nickname">
                {{ rankingData[index + 3].major }}
                {{ rankingData[index + 3].name }}
              </div>
              <img src="@/assets/rank/third.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="big-box" v-if="detail != null">
      <!-- 排行榜内容 -->
      <div class="left">
        <div class="overlay">
          <!-- 这里可以根据你的需求添加排行榜的具体内容 -->
          <div class="box">
            <div class="ranking-list">
              <!-- 排行榜项 -->
              <el-descriptions
                :colon="false"
                :column="6"
                style="margin-top: 2vh; margin-left: 20px"
              >
                <el-descriptions-item>排名</el-descriptions-item>
                <el-descriptions-item>专业</el-descriptions-item>
                <el-descriptions-item>姓名</el-descriptions-item>
                <el-descriptions-item>通关</el-descriptions-item>
                <el-descriptions-item>得分</el-descriptions-item>
                <el-descriptions-item>总耗时</el-descriptions-item>
              </el-descriptions>
              <div v-for="(item, index) in rankingData" :key="index">
                <el-descriptions
                  :colon="false"
                  :column="6"
                  style="
                    margin-top: 2vh;
                    margin-left: 20px;
                    font-family: '微软雅黑';
                  "
                  class="item"
                >
                  <el-descriptions-item>{{ index + 1 }}</el-descriptions-item>
                  <el-descriptions-item>{{ item.major }}</el-descriptions-item>
                  <el-descriptions-item>{{ item.name }}</el-descriptions-item>
                  <el-descriptions-item>{{
                    item.success
                  }}</el-descriptions-item>
                  <el-descriptions-item>{{ item.score }}</el-descriptions-item>
                  <el-descriptions-item>{{ item.time }}</el-descriptions-item>
                </el-descriptions>
              </div>
            </div>
          </div>
        </div>
        <div
          class="description"
          v-if="
            detail != null &&
            detail.description != null &&
            detail.description != ''
          "
        >
          <div class="description-title" style="font-weight: 600">描述：</div>
          <pre
            class="description-content"
            style="margin-left: 30px; margin-top: 30px"
            v-html="detail.description"
          ></pre>
        </div>
      </div>
      <div class="right">
        <el-card>
          <div class="ql-container ql-snow" style="border: none">
            <div class="ql-editor">
              <div id="detail"></div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detailId: "", //路径参数
      rankingData: [], //分数数据
      webURL: this.$axios.defaults.webURL, //前端路径
      detail: null, //文件数据
    };
  },
  mounted() {
    //进行初始化数据
    this.init();
  },
  methods: {
    //初始化数据
    init() {
      //查询路径参数
      this.detailId = this.$route.params.id;
      //路径参数为空
      if (
        this.detailId == null ||
        this.detailId == undefined ||
        this.detailId == "" ||
        this.detailId == "null"
      ) {
        this.$router.replace("/history");
        return;
      }
      //获取数据
      this.GetHistoryDetail();
    },
    //获取数据信息
    GetHistoryDetail() {
      //请求数据
      this.$axios(`/history/detail?id=${this.detailId}`)
        .then((res) => {
          //请求成功
          if (res.data.code == 200) {
            this.rankingData = res.data.data.rank;
            this.detail = res.data.data.detail;
            this.$nextTick(() => {
              if (this.detail != null) {
                //请求详情页数据
                document.getElementById("detail").innerHTML =
                  this.detail.detailFile;
              }
            });

            return;
          }
          //请求失败
          this.$notify.error({ message: res.data.msg, position: "top-left" });
        })
        .catch((error) => {
          // 处理请求错误
          console.error("请求错误:", error);
          this.$notify.error({
            message: "网络较卡，请求数据失败，请刷新重试！",
            position: "top-left",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  padding-top: 30px;
  margin-left: 60px;
}
.rank-box {
  display: flex;
  align-items: center;
  justify-self: center;
  margin: 50px;
  .rank-six {
    .one,
    .two-three,
    .four-six {
      display: flex;
      align-items: center;
      justify-self: center;
      .item {
        margin-left: 100px;
        margin-top: 30px;
        .user {
          margin-top: 20px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-self: center;
          img {
            width: 20px;
            height: 20px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
.big-box {
  zoom: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;

  .left {
    .overlay {
      padding: 20px;
      width: 700px;
      padding-bottom: 100px;
    }
    .description {
      width: 40vw;
    }

    .box {
      display: flex;
      justify-content: center;
      margin: auto;
      margin-top: 30px;
      align-items: center;
      .ranking-list {
        zoom: 0.9;
        /deep/ .el-descriptions__body {
          background-color: #ffffff;
          font-weight: bolder; /*设置字体粗细*/
          color: black;
        }
      }
    }
  }
  .right {
    width: 700px;
    margin-top: 30px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-card {
      width: 1300px;
      background-color: white;
    }
  }
}
@media screen and (max-width: 900px) {
  .title {
    margin-right: 30px;
  }
  .rank-box {
    font-size: 12px;
  }
  .big-box,
  .rank-box {
    zoom: 0.5;
    .description {
      width: 160vw;
    }
  }
}
</style>