import Router from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

import HomePageVue from "@/page/computer/home/HomePage.vue";
import SudoLogin from "@/page/login/SudoLogin.vue";
import HistoryIndex from "@/page/computer/history/HistoryIndex.vue";
import SudoIndex from "@/page/computer/index/SudoIndex.vue";
import PhoneIndex from "@/page/phone/index/PhoneIndex.vue";
import ManageGame from "@/page/computer/admin/ManageGame.vue";
import RankingIndex from "@/page/computer/ranking/RankingIndex.vue";
import SudoAdmin from "@/page/computer/admin/manage/SudoAdmin.vue";
import AdminQuestion from "@/components/admin/AdminQuestion.vue";
import AdminRank from "@/components/admin/AdminRank.vue";
import AdminUser from "@/components/admin/AdminUser.vue";
import AdminOpenParticulars from "@/components/admin/update/AdminOpenParticulars.vue";
import UpdateQuestion from "@/components/admin/update/UpdateQuestion.vue";
import UpdateAdminRank from "@/components/admin/update/UpdateAdminRank.vue";
import HistoryDetail from "@/page/computer/history/detail/HistoryDetail.vue";
import AdminHistory from "@/components/admin/AdminHistory.vue";
import AdminHistoryAdd from "@/components/admin/add/AdminHistoryAdd.vue";
import AdminHistoryUpdate from "@/components/admin/update/AdminHistoryUpdate.vue";
import AccreditIndex from "@/components/admin/AccreditIndex.vue";
import ErrorIndex from "@/page/error/ErrorIndex.vue";
import AdminLog from "@/components/admin/AdminLog.vue";

//创建router
let routerView = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      component: HomePageVue,
    },
    {
      path: "/login",
      component: SudoLogin,
    },
    {
      path: "/index",
      component: SudoIndex,
    },
    {
      path: "/phone",
      component: PhoneIndex,
    },
    {
      path: "/history",
      component: HistoryIndex,
    },
    {
      path: "/detail/:id",
      component: HistoryDetail,
    },
    {
      path: "/admin",
      component: ManageGame,
    },
    {
      path: "/ranking",
      component: RankingIndex,
    },
    {
      path: "/manage",
      component: SudoAdmin,
      children: [
        {
          path: "user",
          component: AdminUser,
          children: [
            {
              path: "update",
              component: AdminOpenParticulars,
            },
          ],
        },
        {
          path: "question",
          component: AdminQuestion,
          children: [
            {
              path: "update",
              component: UpdateQuestion,
            },
          ],
        },
        {
          path: "rank",
          component: AdminRank,
          children: [
            {
              path: "update",
              component: UpdateAdminRank,
            },
          ],
        },
        {
          path: "accredit",
          component: AccreditIndex,
        },
        {
          path: "log",
          component: AdminLog,
        },
        {
          path: "history",
          component: AdminHistory,
          children: [
            {
              path: "add",
              component: AdminHistoryAdd,
            },
            {
              path: "update",
              component: AdminHistoryUpdate,
            },
          ],
        },
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      component: ErrorIndex,
    },
  ],
});

//跳转路由前加载
routerView.beforeEach(async (to, from, next) => {
  if (to.path === from.path) {
    // 阻止重复导航
    next(false);
  } else {
    NProgress.start();
    next();
  }
});
//路由跳转完毕结束
routerView.afterEach(() => {
  NProgress.done();
});

//暴露router路由
export default routerView;
