<template>
  <div class="body">
    <div class="main">
      <div class="stars">
        <div
          class="star"
          v-for="(item, index) in starsCount"
          :key="index"
          ref="star"
        ></div>
      </div>
      <div class="loginBox">
        <h2>数独大赛</h2>
        <div class="rank" v-if="isPhone" @click="ToRank()">岁月志</div>
        <div class="item">
          <input
            @keyup.enter="getPasswordFocus()"
            v-model="login.username"
            type="text"
            required
          />
          <label for="">userName</label>
        </div>
        <div class="item">
          <input
            ref="passwordRef"
            v-model="login.password"
            type="password"
            required
            @keyup.enter="Login()"
          />
          <label for="">passWord</label>
        </div>
        <button class="btn" @click="Login()">
          submit
          <!-- <span></span>
          <span></span>
          <span></span>
          <span></span> -->
        </button>
      </div>
      <BallImport></BallImport>
    </div>
  </div>
</template>

<script>
import BallImport from "@/components/common/BallImport.vue";
import { Encrypt } from "@/utils/secret.js";

export default {
  components: { BallImport },
  data() {
    return {
      login: {
        username: "",
        password: "",
        major: new Date(),
      },
      starsCount: 800, //数量
      distance: 600, //间距
      isPhone: false, //判断是否为移动端
    };
  },
  mounted() {
    //设置背景的星空特效
    this.initStart();
    //获取并清空设置背景,进行初始化
    this.init();
    //进行设置
    this.isMobile();
    if (this.$websocket != null && this.$websocket != undefined) {
      this.$websocket.close();
      this.$websocket = null;
    }
    if (this.$message != null && this.$message != undefined) {
      this.$message.close();
      this.$message = null;
    }
  },
  methods: {
    //跳转至岁月志
    ToRank() {
      // 判断是否为内网环境
      // 获取当前baseUrl
      let baseUrl = this.$axios.defaults.baseURL;
      if (!baseUrl.startsWith("https://")) {
        this.$notify.error({
          message: "当前为内网环境！",
          position: "top-left",
        });
        return;
      }
      //前往历史页面
      this.$router.push("/history");
    },
    //判断是否为移动端
    isMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      this.isPhone = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(
        userAgent
      );
      if (this.isPhone) {
        return false;
      }
      if (!this.isPhone) {
        return true;
      }
    },
    //获取并清空设置背景,进行初始化
    init() {
      //获取背景颜色,获取存储的数据
      let back = localStorage.getItem("back-color");
      let SudoReturn = localStorage.getItem("SudoReturn");
      let log = localStorage.getItem("log");
      //清楚原本的数据
      localStorage.clear();
      //判断是否为null
      if (SudoReturn != null) {
        localStorage.setItem("SudoReturn", SudoReturn);
      }
      if (log != null && log != undefined && log != "") {
        localStorage.setItem("log", log);
      }
      //重新设置进入本地数据
      localStorage.setItem("back-color", back);
    },
    //设置背景的星空特效
    initStart() {
      let starArr = this.$refs.star;
      starArr.forEach((item) => {
        var speed = 0.2 + Math.random() * 1;
        var distance = this.distance + Math.random() * 300;
        item.style.transformOrigin = `0 0 ${distance}px`;
        item.style.transform = `translate3d(0,0,-${distance}px) rotateY(${
          Math.random() * 360
        }deg) rotateX(${Math.random() * -50}deg) scale(${speed},${speed})`;
      });
    },
    //获取焦点
    getPasswordFocus() {
      this.$refs.passwordRef.focus();
    },
    //登录
    Login() {
      //判断输入的账号密码是否为空
      if (this.login.username == "" || this.login.password == "") {
        this.$notify.error({ message: "请输入账号密码", position: "top-left" });
        return;
      }
      //进行密码加密
      this.login.password = Encrypt(this.login.password);
      //发起请求请求登录
      this.$axios.post("/user/login", this.login).then((res) => {
        //登录成功
        if (res.data.code == "200") {
          this.$notify.success({
            message: "登录成功-ip地址：" + res.data.data.ip,
            position: "top-left",
            duration: 800,
          });
          //设置用户的ip地址信息
          localStorage.setItem("ip", res.data.data.ip);
          //设置用户的认证token信息
          localStorage.setItem("token", res.data.data.token);
          //设置用户的id信息
          localStorage.setItem("id", res.data.data.id);
          //设置用户的姓名信息
          localStorage.setItem("username", this.login.username);
          //判断为管理员
          if (res.data.msg == "admin") {
            setTimeout(() => {
              this.$notify.success({
                message: "欢迎您，裁判",
                position: "top-left",
              });
              this.$router.replace("/admin");
            }, 1000);
            return;
          }
          //提示用户
          setTimeout(() => {
            this.$notify.warning({
              message: res.data.msg,
              position: "top-left",
            });
          }, 1000);
          //判断是否为手机登录
          if (!this.isMobile()) {
            this.$router.replace("/phone");
            return;
          }
          //跳转主页竞赛
          this.$router.replace("/index");
        } else {
          //清空密码
          this.login.password = "";
          //根据回调进行判断
          if (res.data.msg == "该用户已登录") {
            this.$notify.warning({
              message: "该用户已登录",
              position: "top-left",
            });
            return;
          }
          //当前为禁止登录状态
          if (res.data.msg == "管理员已禁止登录") {
            this.$notify.warning({
              message: "当前为不可登录状态",
              position: "top-left",
            });
            setTimeout(() => {
              this.$notify.warning({
                message: "请耐心等待裁判开启比赛！",
                position: "top-left",
              });
            }, 500);
            return;
          }
          //当前为禁止提交状态
          if (res.data.msg == "当前管理员禁止提交，拒绝授权登录！") {
            this.$notify.warning({
              message: "当前管理员禁止提交，拒绝授权登录！",
              position: "top-left",
            });
            setTimeout(() => {
              this.$notify.warning({
                message: "若练习模式请联系主办方,若竞赛模式则比赛已结束！",
                position: "top-left",
              });
            }, 500);
            return;
          }
          //密码错误，登录失败
          this.$notify.error({ message: res.data.msg, position: "top-left" });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
a {
  text-decoration: none;
}

input,
button {
  background: transparent;
  border: 0;
  outline: none;
}

.body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #03e9f4;
}

.loginBox {
  width: 400px;
  height: 364px;
  background-color: #0c162223;
  border-radius: 10px;
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.6);
  padding: 40px;
  box-sizing: border-box;
  position: relative;
  top: 45%;
  left: 50%;
  translate: -50% -45%;
  span {
    font-size: 18px;
    margin-left: 1vw;
  }
}
.rank {
  cursor: pointer;
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #efe529;
}

h2 {
  text-align: center;
  color: aliceblue;
  margin-bottom: 30px;
  font-family: "Courier New", Courier, monospace;
}

.item {
  height: 45px;
  border-bottom: 1px solid #fff;
  margin-bottom: 40px;
  position: relative;
}

.item input {
  width: 100%;
  height: 100%;
  color: #fff;
  padding-top: 20px;
  box-sizing: border-box;
}

.item input:focus + label,
.item input:valid + label {
  top: 0px;
  font-size: 2px;
}

.item label {
  position: absolute;
  left: 0;
  top: 12px;
  transition: all 0.5s linear;
}

.btn {
  padding: 10px 20px;
  margin-top: 30px;
  color: #03e9f4;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  letter-spacing: 2px;
  left: 35%;
}

.btn:hover {
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  background: #03e9f4;
  box-shadow: 0 0 5px 0 #03e9f4, 0 0 25px 0 #03e9f4, 0 0 50px 0 #03e9f4,
    0 0 100px 0 #03e9f4;
  transition: all 1s linear;
}

.main {
  width: 100vw;
  min-width: 1200px;
  height: 100vh;
  background-color: #fff;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  overflow: hidden;
  filter: drop-shadow(0 0 10px white);
  position: relative;
  .main-title {
    position: absolute;
    left: 50%;
    top: 70px;
    transform: translateX(-50%);
    font-size: 30px;
    color: rgba(255, 255, 255, 1);
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -28px;
      width: 28px;
      height: 4px;
      background: rgba(244, 54, 63, 1);
      border-radius: 2px;
    }
  }
  .stars {
    transform: perspective(500px);
    transform-style: preserve-3d;
    position: absolute;
    perspective-origin: 50% 100%;
    left: 50%;
    animation: rotate 90s infinite linear;
    bottom: -256px;
    .star {
      width: 2px;
      height: 2px;
      background: #f7f7b8;
      position: absolute;
      top: 0;
      left: 0;
      backface-visibility: hidden;
    }
    @keyframes rotate {
      0% {
        transform: perspective(400px) rotateZ(20deg) rotateX(-40deg) rotateY(0);
      }
      100% {
        transform: perspective(400px) rotateZ(20deg) rotateX(-40deg)
          rotateY(-360deg);
      }
    }
  }

  .dot-num {
    position: relative;
    height: 200px;
    width: 100%;
    .dot {
      position: absolute;
      top: 0;
      display: inline-block;
      .dot-title {
        font-size: 40px;
        line-height: 37px;
        font-weight: bold;
        color: #f23340;
      }
      .dot-describe {
        margin-top: 10px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.8);
        font-size: 14px;
      }
    }
  }
}
</style>
