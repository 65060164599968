<template>
  <div class="box">
    <el-card>
      <div @click="ToHistoryDetail(item.historyDetailId)">
        <div class="header">
          <el-image
            :src="webURL + item.historyImg"
            :preview-src-list="[webURL + item.historyImg]"
            @click.stop
          >
          </el-image>
        </div>
        <div class="center">
          <div class="title">活动名称：{{ item.historyTitle }}</div>
          <div class="sub-title">活动主题：{{ item.historySubTitle }}</div>
          <div class="game-time">活动时间：{{ item.historyTime }}</div>
          <div class="game-description">
            活动描述：{{ item.historyDescription }}
          </div>
          <div class="game-bear" @click.stop="OpenGamePdf(item.historyFile)">
            活动文件：<span>点击查看</span>
          </div>
        </div>
        <div class="foote"></div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      webURL: this.$axios.defaults.webURL, //前端路径
    };
  },
  mounted() {},
  methods: {
    //前往历史详情页面
    ToHistoryDetail(id) {
      //跳转页面
      this.$router.push(`/detail/${id}`);
    },
    //打开数独大赛文件
    OpenGamePdf(historyFile) {
      window.open(this.webURL + historyFile);
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  zoom: 0.6;
  padding: 20px;
  cursor: pointer;
  .el-card {
    width: 550px;
    .header {
      width: 500px;
      height: 300px;
      .el-image {
        width: 100%;
        height: 100%;
      }
    }
    .center {
      .title {
        font-weight: 600;
        padding: 20px;
        line-height: 30px;
        font-size: 20px;
      }
      .sub-title {
        padding: 10px;
        line-height: 30px;
      }
      .game-time {
        padding: 10px;
        line-height: 30px;
      }
      .game-description {
        padding: 10px;
        line-height: 30px;
      }
      .game-bear {
        padding: 10px;
        line-height: 30px;
        span {
          border-bottom: 1px solid black;
        }
        span:hover {
          color: #166ba8;
          border-bottom: 1px solid #166ba8;
        }
      }
    }
    .footer {
    }
  }
}
@media screen and (min-width: 900px) {
  .box {
    zoom: 0.9;
  }
}
</style>