<template>
  <div>
    <socket />
    <div id="box">
      <div class="left">
        <div class="student-number">
          <div class="time">{{ time }}</div>
          <div style="margin-left: 2vw; font-size: 3.5vw">
            学号：{{ username }}
          </div>
        </div>
        <div class="header">
          <div class="top">
            <div
              @click="clickSudo(item, index)"
              v-for="(item, index) in sudoUserData"
              :key="index"
              id="cell"
              class="cell"
              :class="{
                selected: selectedIndex === index,
                clickColor: sudoDataList[index] === 0,
                clickSize: Array.isArray(sudoUserData[index]),
                choose:
                  sudoUserData[index] == chooseColor ||
                  (Array.isArray(sudoUserData[index]) &&
                    sudoUserData[index].includes(chooseColor)),
              }"
            >
              {{ Array.isArray(item) ? item.join(" ") : item || " " }}
            </div>
          </div>
        </div>
        <div class="number" id="number">
          <div
            @click="ChangeNumber(item)"
            class="item"
            v-for="item in number"
            :key="item"
            :class="{ chooseNumberColor: notepad }"
          >
            {{ item }}
          </div>
        </div>
        <div class="footer">
          <el-button
            id="notepad"
            :class="{ btnClick: this.notepad }"
            @click="clickNotepad()"
            >笔记{{ this.notepad ? "（开）" : "（关）" }}</el-button
          >
          <el-button @click="SudoReturn()">撤回</el-button>
          <el-button @click="Appeal()">申诉</el-button>
          <el-button id="nextTopic" @click="NextTopic()">下一题</el-button>
        </div>
      </div>
      <div style="height: 2vh; position: fixed; top: 0px; right: 0px">
        <el-button
          style="background-color: #78746e; color: white"
          @click="showRank = !showRank"
          v-show="!showRank"
          size="mini"
          icon="el-icon-arrow-down"
        ></el-button>
      </div>
      <div class="right" :class="showRank ? 'show-rank' : ''" size="mini">
        <div style="height: 2vh; position: fixed; top: 0px; right: 0px">
          <el-button
            style="background-color: #78746e; color: white"
            @click="showRank = !showRank"
            v-show="showRank"
            size="mini"
            icon="el-icon-arrow-up"
          ></el-button>
        </div>
        <!-- 蒙层内容 -->
        <div class="overlay">
          <!-- 排行榜内容 -->
          <div class="title">英雄榜</div>
          <!-- 这里可以根据你的需求添加排行榜的具体内容 -->
          <div class="ranking-list">
            <!-- 排行榜项 -->
            <el-descriptions
              :colon="false"
              :column="6"
              style="margin-top: 2vh; margin-left: 2vw"
            >
              <el-descriptions-item>排名</el-descriptions-item>
              <el-descriptions-item>专业</el-descriptions-item>
              <el-descriptions-item>姓名</el-descriptions-item>
              <el-descriptions-item>通关</el-descriptions-item>
              <el-descriptions-item>得分</el-descriptions-item>
              <el-descriptions-item>总耗时</el-descriptions-item>
            </el-descriptions>
            <div v-for="(item, index) in rankingData" :key="index">
              <el-descriptions
                :colon="false"
                :column="6"
                style="margin-top: 2vh; margin-left: 2vw"
                class="item"
              >
                <el-descriptions-item>{{ index + 1 }}</el-descriptions-item>
                <el-descriptions-item>{{ item.major }}</el-descriptions-item>
                <el-descriptions-item>{{ item.nickname }}</el-descriptions-item>
                <el-descriptions-item>{{
                  item.successNumber
                }}</el-descriptions-item>
                <el-descriptions-item>{{ item.score }}</el-descriptions-item>
                <el-descriptions-item>{{ item.time }}</el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
        </div>
      </div>
      <div class="update-button">
        <el-dropdown @command="SetUpdateBack">
          <el-button id="bizhi">主题切换</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="-1">主题1</el-dropdown-item>
            <el-dropdown-item command="0">主题2</el-dropdown-item>
            <el-dropdown-item command="1">主题3</el-dropdown-item>
            <el-dropdown-item command="2">主题4</el-dropdown-item>
            <el-dropdown-item command="3">主题5</el-dropdown-item>
            <el-dropdown-item command="4">restart</el-dropdown-item>
            <el-dropdown-item command="5">close</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
//导入引导
import introJs from "intro.js";
import "intro.js/introjs.css";
//导入socket连接
import socket from "@/components/socket/WebSocket.vue";
export default {
  components: { socket },
  data() {
    return {
      notepad: false, //是否在笔记状态
      sudoDataList: new Array(81).fill(0), //存放二类数据
      sudoUserData: new Array(81).fill(0), //用户输入数据
      number: [1, 2, 3, 4, 5, 6, 7, 8, 9], //数字信息
      selectedIndex: null, //用户选中状态
      ip: localStorage.getItem("ip"), //用户ip
      rankingData: [], //排行榜数据
      time: "00：00：00", //时间
      startTime: localStorage.getItem("time") || new Date(), //存放开始的时间
      username: localStorage.getItem("username"), //用户学号
      showRank: true, //是否展示蒙层
      topicId: localStorage.getItem("topicId") || 1, //题目id
      id: localStorage.getItem("id"), //用户id数据
      chooseColor: null, //选择颜色
      SudoSelectIndexReturn: null, //选中文字
      clickNextButton: false, //加载动画不得继续加载题目
      updateBack: localStorage.getItem("back-color") || 0, //修改壁纸颜色
      sudokuGrids: [
        // 第一个小九宫格
        [1, 2, 3, 10, 11, 12, 19, 20, 21],
        // 第二个小九宫格
        [4, 5, 6, 13, 14, 15, 22, 23, 24],
        // 第三个小九宫格
        [7, 8, 9, 16, 17, 18, 25, 26, 27],
        // 第四个小九宫格
        [28, 29, 30, 37, 38, 39, 46, 47, 48],
        // 第五个小九宫格
        [31, 32, 33, 40, 41, 42, 49, 50, 51],
        // 第六个小九宫格
        [34, 35, 36, 43, 44, 45, 52, 53, 54],
        // 第七个小九宫格
        [55, 56, 57, 64, 65, 66, 73, 74, 75],
        // 第八个小九宫格
        [58, 59, 60, 67, 68, 69, 76, 77, 78],
        // 第九个小九宫格
        [61, 62, 63, 70, 71, 72, 79, 80, 81],
      ], //九宫格数据
      topicRouter: true, //当前竞赛状态是否为竞赛
      undoStack: [], //存放撤回操作
    };
  },
  mounted() {
    //判断是否有key
    this.ifHaveToken();
    // //获取是否在比赛状态
    this.SudoStatus();
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeUnload);
    window.removeEventListener("keydown", this.addKeydown);
    //监听缓存被用户修改
    window.removeEventListener("storage", this.UserChangeStorage);
    // window.removeEventListener("visibilitychange", this.ListentLeavePage);
  },
  methods: {
    //监听管理员将用户提出比赛
    kickOut() {
      this.$bus.$on("kickOut", () => {
        //通知用户
        this.$notify.error({
          message: "您已被管理员提出比赛",
          position: "top-left",
        });
        //设置离开指令
        localStorage.setItem("leave", "leave");
        //前往登录页面
        this.$router.replace("/login");
      });
    },
    AdminUpdateStatus() {
      this.$bus.$on("adminUpdateStatus", () => {
        //通知用户
        this.$notify.warning({
          message: "管理员修改了比赛状态",
          position: "top-left",
        });
        //设置离开指令
        localStorage.setItem("leave", "leave");
        //前往登录页面
        this.$router.replace("/login");
      });
    },
    //监听是否离开页面
    ListentLeavePage() {
      window.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "visible") {
          this.$notify.success({
            message: "欢迎回来",
            position: "bottom-left",
            duration: 700,
          });
        }
      });
    },
    //获取排行榜数据
    GetRankingData() {
      //发起请求获取排行榜数据信息
      this.GetRankingDataList();
      //发起请求获取排行榜数据信息
      this.$bus.$on("getRanking", () => {
        //发起请求获取数据
        this.GetRankingDataList();
      });
    },
    //发起请求获取排行榜数据
    GetRankingDataList() {
      //发起请求
      this.$axios.get("/user/getUserHero").then((res) => {
        //循环排行榜数组,如果里面的id都没有自己的id,则直接顶出去用户
        this.rankingData = res.data.data;
        //定义数据
        let bool = false;
        //循环遍历
        for (let i = 0; i < this.rankingData.length; i++) {
          //如果里面的数据等于
          if (this.rankingData[i].id == this.id) {
            bool = true;
          }
        }
        //如果符合则回退路由
        if (!bool) {
          //设置退出
          localStorage.setItem("leave", "leave");
          this.$router.replace("/login");
        }
      });
    },
    //主题切换
    SetUpdateBack(command) {
      //判断是否为重新建立连接操作
      if (command == "4") {
        this.$prompt("请输入连接密钥", "计算机协会", { type: "warning" })
          .then((accept) => {
            //判断是否为重连密码
            if (accept.value == "请输入连接密钥") {
              this.$notify.warning({
                message: "正在重连",
                position: "top-left",
              });
              this.$bus.$emit("RestartConnSocket");
            } else {
              this.$notify.error({ message: "错误信息", position: "top-left" });
            }
          })
          .catch(() => {});
        return;
      }
      //判断是否为关闭连接操作
      if (command == "5") {
        this.$prompt("请输入关闭密钥", "计算机协会", { type: "warning" })
          .then((accept) => {
            //判断是否为重连密码
            if (accept.value == "请输入关闭密钥") {
              //判断当前状态
              if (this.$websocket != null && this.$websocket != undefined) {
                this.$websocket.close();
                return;
              }
              //提示用户
              this.$notify.error({
                message: "当前处于未连接状态",
                position: "top-left",
              });
            } else {
              this.$notify.error({ message: "错误信息", position: "top-left" });
            }
          })
          .catch(() => {});
        return;
      }
      this.updateBack = parseInt(command);
      //判断是否为重新建立连接
      this.UpdateBack();
    },
    //是否在比赛状态
    SudoStatus() {
      //请求数据
      this.$axios.get(`/user/status?id=${this.id}`).then((res) => {
        //请求成功为竞赛状态
        if (res.data.code == 200) {
          //解析后端传来的时间
          this.startTime = new Date(res.data.data.startTime);
          // 假设 res.data.data.successNumber 是一个数字
          var successNumber = res.data.data.successNumber;
          // 确保 successNumber 是一个数字，如果不是，转换它
          if (typeof successNumber !== "number") {
            successNumber = parseInt(successNumber, 10);
          }
          // 计算最终的 topicId 值
          this.topicId = parseInt(this.topicId + successNumber + 9, 10);
          //判断id是否大于14，如果大于则前往排行榜页面
          if (this.topicId > 14) {
            //跳往排行榜页面
            this.$router.replace("/ranking");
            return;
          }
        }
        //询问是否开始新手引导，加载js监听事件
        this.ifNoviciate();
        // //修改背景样式
        this.UpdateBack();
        //获取排行榜数据信息
        this.GetRankingData();
        //监听是否离开页面
        this.ListentLeavePage();
        //监听管理员将用户提出比赛
        this.kickOut();
        //监听管理员修改比赛状态
        this.AdminUpdateStatus();
        //获取题目数据
        this.GetQuestionTopic();
      });
    },
    //出现问题解决
    Problem() {
      this.GetQuestionTopic();
      //获取存放的输入数据
      this.$notify.success({
        message: "已重新加载题目",
        position: "top-left",
      });
      setTimeout(() => {
        this.$notify.warning({
          message: "若仍有问题请反馈",
          position: "top-left",
        });
      }, 500);
      //存入缓存
      setTimeout(() => {
        this.$notify.warning({
          message: "已把数据存入本地，可通过撤回查看",
          position: "top-left",
        });
      }, 1500);
      localStorage.setItem("SudoReturn", JSON.stringify(this.sudoUserData));
    },
    //修改背景样式
    UpdateBack() {
      if (
        this.updateBack == null ||
        isNaN(this.updateBack) ||
        this.updateBack == undefined
      ) {
        localStorage.setItem("back-color", 0);
        this.updateBack = 0;
      }
      if (this.updateBack < -1 || this.updateBack > 4) {
        this.updateBack = 0;
      }
      if (this.updateBack == 4) {
        this.updateBack = -1;
      }
      this.updateBack++;
      if (this.updateBack == 0) {
        document.documentElement.style.setProperty("--back", "#20344c");
        document.documentElement.style.setProperty("--cell-back", "#0c1622");
        document.documentElement.style.setProperty("--cell-color", "#03e9f4");
        document.documentElement.style.setProperty("--cell-choose", "#248466");
        document.documentElement.style.setProperty("--cell-click", "#20344c");
        document.documentElement.style.setProperty("--click-size", "#d371ea");
        document.documentElement.style.setProperty("--cell-number", "red");
        document.documentElement.style.setProperty(
          "--number-mask-color",
          "#03e9f4"
        );
        document.documentElement.style.setProperty("--number-mask", "#0c1622");
        document.documentElement.style.setProperty("--number-color", "white");
        document.documentElement.style.setProperty("--back-color", "black");
        document.documentElement.style.setProperty("--button-color", "black");
        document.documentElement.style.setProperty("--button-back", "#03e9f4");
      } else if (this.updateBack == 1) {
        document.documentElement.style.setProperty("--back", "#f0eadc");
        document.documentElement.style.setProperty("--cell-back", "#f0eadc");
        document.documentElement.style.setProperty("--cell-color", "black");
        document.documentElement.style.setProperty("--cell-choose", "#8ca33a");
        document.documentElement.style.setProperty("--cell-click", "#e8d4a4");
        document.documentElement.style.setProperty("--cell-number", "red");
        document.documentElement.style.setProperty(
          "--number-mask-color",
          "#e05b5f"
        );
        document.documentElement.style.setProperty("--number-mask", "#e8d4a4");
        document.documentElement.style.setProperty("--number-color", "black");
        document.documentElement.style.setProperty("--back-color", "black");
        document.documentElement.style.setProperty("--button-color", "black");
        document.documentElement.style.setProperty("--button-back", "#e05b5f");
        document.documentElement.style.setProperty("--click-size", "blue");
      } else if (this.updateBack == 2) {
        document.documentElement.style.setProperty("--back", "#ffffff");
        document.documentElement.style.setProperty("--cell-back", "#ffffff");
        document.documentElement.style.setProperty("--cell-color", "black");
        document.documentElement.style.setProperty("--cell-choose", "#1aad19");
        document.documentElement.style.setProperty("--cell-click", "#93deca");
        document.documentElement.style.setProperty("--cell-number", "red");
        document.documentElement.style.setProperty(
          "--number-mask-color",
          "#e05b5f"
        );
        document.documentElement.style.setProperty("--number-mask", "#93deca");
        document.documentElement.style.setProperty("--number-color", "black");
        document.documentElement.style.setProperty("--back-color", "black");
        document.documentElement.style.setProperty("--button-color", "black");
        document.documentElement.style.setProperty("--button-back", "#22a7f2");
        document.documentElement.style.setProperty("--click-size", "#535689");
      } else if (this.updateBack == 3) {
        document.documentElement.style.setProperty("--back", "#24292e");
        document.documentElement.style.setProperty("--cell-back", "#202124");
        document.documentElement.style.setProperty("--cell-color", "white");
        document.documentElement.style.setProperty("--cell-choose", "#98b2ff");
        document.documentElement.style.setProperty("--cell-click", "#e4e2e0");
        document.documentElement.style.setProperty("--cell-number", "#3068ce");
        document.documentElement.style.setProperty(
          "--number-mask-color",
          "white"
        );
        document.documentElement.style.setProperty("--number-mask", "#99b2fd");
        document.documentElement.style.setProperty("--number-color", "white");
        document.documentElement.style.setProperty("--back-color", "black");
        document.documentElement.style.setProperty("--button-color", "black");
        document.documentElement.style.setProperty("--button-back", "#99b2fd");
        document.documentElement.style.setProperty("--click-size", "red");
      } else if (this.updateBack == 4) {
        document.documentElement.style.setProperty("--back", "black");
        document.documentElement.style.setProperty("--cell-back", "white");
        document.documentElement.style.setProperty("--cell-color", "black");
        document.documentElement.style.setProperty("--cell-choose", "#ee7a15");
        document.documentElement.style.setProperty("--cell-click", "#f4d176");
        document.documentElement.style.setProperty("--cell-number", "red");
        document.documentElement.style.setProperty(
          "--number-mask-color",
          "black"
        );
        document.documentElement.style.setProperty("--number-mask", "white");
        document.documentElement.style.setProperty("--number-color", "white");
        document.documentElement.style.setProperty("--back-color", "white");
        document.documentElement.style.setProperty("--button-color", "yellow");
        document.documentElement.style.setProperty("--button-back", "#7f0000 ");
        document.documentElement.style.setProperty("--click-size", "red");
      }

      localStorage.setItem("back-color", this.updateBack - 1);
    },
    //监听缓存被用户修改
    UserChangeStorage() {
      localStorage.setItem("leave", "leave");
      this.$notify.error({
        message: "系统检测状态异常！",
        position: "top-left",
      });
      //跳转
      this.$router.replace("/home");
      //移除监听缓存
      //监听缓存被用户修改
      window.removeEventListener("storage", this.UserChangeStorage);
    },
    //初始化参数内容
    Initialize() {
      //监听屏幕刷新
      window.addEventListener("beforeunload", this.beforeUnload);
      //监听缓存被用户修改
      window.addEventListener("storage", this.UserChangeStorage);
      // 每秒更新一次计时器
      setInterval(this.updateTimer, 1000);
      //监听键盘事件
      window.addEventListener("keydown", this.addKeydown);
      //判断是否是第一套题目,提示数字乾坤
      if (this.topicId == 1 || this.topicId == 10) {
        setTimeout(() => {
          if (this.showRank) {
            this.showRank = false;
            this.$notify({
              message: "数字乾坤，我来一探究竟",
              position: "bottom-right",
            });
          }
        }, 1000);
      }
    },
    //是否进行新手引导？
    ifNoviciate() {
      if (this.topicId == 1) {
        //判断是否是第一次进入
        if (localStorage.getItem("DontTip") == null) {
          this.$confirm("是否进行新手引导？", "计算机协会", { type: "info" })
            .then(() => {
              this.Noviciate();
            })
            .catch(() => {
              this.Initialize();
            });
        } else {
          localStorage.removeItem("DontTip");
          this.Initialize();
        }
      } else {
        this.Initialize();
      }
    },
    //进行新手引导
    Noviciate() {
      let intro = introJs();
      // 通过id或者class绑定步骤，也可以直接定死在div上面
      let steps = [
        {
          element: ".cell",
          intro: "点击所需填入数字的方框",
          position: "right",
        },
        {
          element: ".number",
          intro: "点击所需填入的数字，再次点击任意数字取消所填入数字",
          position: "right",
        },
        {
          element: "#notepad",
          intro: "点击可开启笔记功能",
          position: "top",
        },
        {
          element: ".header",
          intro: "可点击键盘填入数字",
          position: "center",
        },
        {
          element: "#bizhi",
          intro: "点击可切换颜色",
          position: "center",
        },
        {
          element: "#nextTopic",
          intro: "填入所需空后可进入下一题",
          position: "right",
        },
        {
          element: ".right",
          intro: "实时显示选手得分情况",
          position: "left",
        },
      ];
      intro
        .setOptions({
          // 我给看的效果图是英文的（默认英文），这里是更换成中文
          prevLabel: "上一步",
          nextLabel: "下一步",
          skipLabel: "跳过",
          doneLabel: "完成",
          // steps步骤，可以写个工具类保存起来
          steps: steps,
        })
        .oncomplete(() => {
          // 这里用了箭头函数
          // 点击结束按钮后执行的事件
        })
        .onexit(() => {
          // 点击跳过按钮后执行的事件
        })
        .onbeforeexit(() => {
          // 确认完毕之后执行的事件
          this.Initialize();
        })
        .start();
    },
    //赛员有问题提出申诉
    Appeal() {
      this.$confirm("是否反馈裁判？", "计算机协会", { type: "error" })
        .then(() => {
          let loading = this.$loading({
            lock: true,
            text: "正在为您联系裁判长...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 1)",
          });
          setTimeout(() => {
            this.$confirm(
              "无法联系到裁判长，请手动联系工作人员",
              "计算机协会",
              { type: "info" }
            )
              .then(() => {
                loading.text = "无法联系到裁判长，请手动联系工作人员";
                setTimeout(() => {
                  loading.close();
                }, 3000);
              })
              .catch(() => {
                loading.close();
              });
          }, 3000);
        })
        .catch(() => {
          this.$notify.error({ message: "已取消反馈", position: "top-left" });
        });
    },
    //撤回功能
    SudoReturn() {
      //判断本地是否存在撤回数据
      let sudoReturn = localStorage.getItem("SudoReturn");
      //判断是否有信息
      if (sudoReturn != null) {
        //进行撤回数据
        this.sudoUserData = JSON.parse(sudoReturn);
        //移除本地缓存
        localStorage.removeItem("SudoReturn");
        return;
      }
      if (this.undoStack.length > 0) {
        // 从undoStack中弹出最后一个快照
        const snapshot = this.undoStack.pop();
        // 将快照赋值给数组
        this.sudoUserData = JSON.parse(JSON.stringify(snapshot));
      } else {
        this.$notify.error({ message: "没有可撤回数据", position: "top-left" });
      }
    },
    //提示事件
    GetQuestionAnswer() {
      //判断选中是否为空
      if (this.selectedIndex == null) {
        return;
      }
      //设置当前选中的index
      let index = this.selectedIndex;
      //判断id是否为设置的id
      if (this.id == "3035b0fa6baf49af2284eadc8f05db1b123123") {
        //请求题目数据
        this.$axios
          .get(
            `/Admin/GetQuestionAnswer?topicId=${this.topicId}&index=${index}&id=${this.id}`
          )
          .then((res) => {
            if (res.data.code == 200) {
              this.sudoUserData[index] = res.data.data;
              this.undoStack.push(
                JSON.parse(JSON.stringify(this.sudoUserData))
              );
            } else {
              this.$notify.error({ message: "系统出错", position: "top-left" });
            }
          });
      }
    },
    //上下左右移动事件
    UpDownLeftRight(code) {
      if (this.selectedIndex != null) {
        //左键盘
        if ((code == 37 || code == 65) && this.selectedIndex > 0) {
          this.selectedIndex -= 1;
        } else if ((code == 37 || code == 65) && this.selectedIndex == 0) {
          this.selectedIndex = 80;
        }
        //上键盘
        if ((code == 38 || code == 87) && this.selectedIndex > 8) {
          this.selectedIndex -= 9;
        } else if ((code == 38 || code == 87) && this.selectedIndex <= 8) {
          this.selectedIndex += 72;
        }
        //右键盘
        if ((code == 39 || code == 68) && this.selectedIndex < 80) {
          this.selectedIndex += 1;
        } else if ((code == 39 || code == 68) && this.selectedIndex == 80) {
          this.selectedIndex = 0;
        }
        //下键盘
        if ((code == 40 || code == 83) && this.selectedIndex < 72) {
          this.selectedIndex += 9;
        } else if ((code == 40 || code == 83) && this.selectedIndex >= 72) {
          this.selectedIndex -= 72;
        }
        this.clickSudo(
          this.sudoUserData[this.selectedIndex],
          this.selectedIndex
        );
      }
    },
    //键盘监听事件
    addKeydown(e) {
      if (
        e.keyCode == 37 ||
        e.keyCode == 38 ||
        e.keyCode == 39 ||
        e.keyCode == 40 ||
        e.keyCode == 65 ||
        e.keyCode == 87 ||
        e.keyCode == 68 ||
        e.keyCode == 83
      ) {
        this.UpDownLeftRight(e.keyCode);
      }
      if (e.key == "0") {
        this.GetQuestionAnswer();
        return;
      }
      if (e.code == "Space") {
        this.clickNotepad();
      }
      if (e.key == "Escape") {
        this.showRank = !this.showRank;
      }
      if (e.key == "Backspace") {
        this.SudoReturn();
        return;
      }
      if (this.selectedIndex == null) {
        return;
      }
      if (!/^[1-9]$/gi.test(e.key)) {
        return;
      }
      this.ChangeNumber(e.key);
    },
    //判断是否登录
    ifHaveToken() {
      //获取并判断id和token是否为空
      if (
        localStorage.getItem("token") == null ||
        localStorage.getItem("id") == null
      ) {
        localStorage.setItem("leave", "leave");
        this.$router.replace("/");
        return;
      }
      //获取历史存放的撤回数据
      let SudoReturn = localStorage.getItem("SudoReturn");
      //判断撤回数据是否为空
      if (SudoReturn != null) {
        this.undoStack.push(JSON.parse(SudoReturn));
        localStorage.removeItem("SudoReturn");
      }
    },
    //获取数据
    GetQuestionTopic() {
      this.$axios
        .get(`/Question/GetQuestionTopic?id=${this.id}&topicId=${this.topicId}`)
        .then((res) => {
          if (res.data.code == 200) {
            this.sudoDataList = JSON.parse(JSON.stringify(res.data.data));
            this.sudoUserData = JSON.parse(JSON.stringify(res.data.data));
            //再次获取题目进行题目校验
            this.$axios
              .get(
                `/Question/GetQuestionTopic?id=${this.id}&topicId=${this.topicId}`
              )
              .then((response) => {
                //获取题目判断
                let secondDataList = JSON.parse(
                  JSON.stringify(response.data.data)
                );
                //设置变量值
                let bool = false;
                //循环遍历判断题目
                for (let i = 0; i < secondDataList.length; i++) {
                  //判断是否不等
                  if (secondDataList[i] != this.sudoDataList[i]) {
                    bool = true;
                  }
                }
                //如果变量为真则代表题目不一致
                if (bool) {
                  this.$notify.warning({
                    message:
                      "二次获取题目校验失败，题目异常，请点击重新获取题目！",
                  });
                } else {
                  //提示用户
                  this.$notify.success({
                    message: "题目已加载完成",
                    position: "top-left",
                  });
                  console.log("二次获取题目校验正常");
                }
              });
          } else {
            this.$notify.error({
              message: res.data.msg,
              position: "top-left",
            });
          }
        });
    },
    //下一题
    NextTopic() {
      //调用方法判断用户是否有未填空的数据
      let bool = this.SeleteUserData();
      if (bool) {
        this.$notify.warning({
          message: "您的答案未填完整",
          position: "top-left",
        });
      }
      //询问用户是否提交
      this.$confirm("是否前往下一题？注：无法返回上一题", "计算机协会", {
        type: "warning",
      })
        .then(() => {
          //开始设置加载
          const loading = this.$loading({
            lock: true,
            text: "正在上传",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0)",
          });
          //进行提交
          let answer = {
            id: this.id, //用户id
            answers: this.sudoUserData, //用户答案数组
            topicId: this.topicId, //题目id数据
            time: this.time, //提交时间
          }; //创建答案进行提交
          //进行提交数据
          this.$axios.post("/Question/PutAnswer", answer).then((res) => {
            loading.close();
            //上传成功
            if (res.data.code == 200) {
              this.$notify.success({
                message: "提交成功",
                position: "top-left",
              });
              //如果题目超出23题则证明题目已完成，直接跳转
              if (this.topicId >= 23) {
                this.$notify.success({ message: "题目已全部完成" });
                //跳往排行榜页面
                this.$router.replace("/ranking");
              } else {
                //获取下一题数据
                this.NextGetTopic();
              }
              return;
            }
            this.$notify.error({ message: res.data.msg, position: "top-left" });
          });
        })
        .catch(() => {});
    },
    //调用方法判断用户是否有未填空的数据
    SeleteUserData() {
      //定义bool值
      let bool = false;
      //开始循环
      for (let i = 0; i < this.sudoUserData.length; i++) {
        if (this.sudoUserData[i] == 0) {
          bool = true;
        }
      }
      return bool;
    },
    //设置题目加价,让数据进行下一步获取
    NextGetTopic() {
      //设置下一题题目数据
      this.topicId++;
      //让撤回数据为空
      this.undoStack = [];
      //获取下一道题目数据
      this.GetQuestionTopic();
    },
    //监听屏幕刷新动作
    beforeUnload(e) {
      localStorage.setItem("SudoReturn", JSON.stringify(this.sudoUserData));
      localStorage.setItem("time", this.startTime);
      //判断题目是否大于10
      if (this.topicId < 10) {
        localStorage.setItem("topicId", this.topicId);
      }
      localStorage.setItem("DontTip", "DontTip");
      e.returnValue = "关闭提示";
    },
    //计算时间
    updateTimer() {
      let currentTime = new Date();
      let timeDifference = currentTime - new Date(this.startTime);
      let seconds = Math.floor((timeDifference / 1000) % 60);
      let minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
      let hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);

      // 格式化显示时间
      let formattedTime =
        this.pad(hours) + ":" + this.pad(minutes) + ":" + this.pad(seconds);
      // 更新页面上的计时器显示
      this.time = formattedTime;
    },
    // 补零函数，确保显示的时间格式为两位数
    pad(number) {
      return (number < 10 ? "0" : "") + number;
    },
    //点击改变笔记
    clickNotepad() {
      this.notepad = !this.notepad;
    },
    //点击数独方框
    clickSudo(item, index) {
      if (this.sudoUserData[index] == 0) {
        this.chooseColor = null;
      }
      if (/^[1-9]$/.test(this.sudoUserData[index]) && !Array.isArray(item)) {
        this.chooseColor = this.sudoUserData[index];
      }
      if (this.sudoDataList[index] != 0) {
        return;
      }
      if (this.selectedIndex != index) {
        this.selectedIndex = index;
      }
    },
    //点击数组
    ChangeNumber(item) {
      if (this.sudoDataList[this.selectedIndex] != 0) {
        return;
      }
      this.undoStack.push(JSON.parse(JSON.stringify(this.sudoUserData)));
      item = parseInt(item);
      if (this.selectedIndex !== null) {
        if (this.notepad) {
          // 如果是 notepad 模式
          const currentCellData = this.sudoUserData[this.selectedIndex];

          // 检查是否已经存在相同的数字
          if (
            Array.isArray(currentCellData) &&
            currentCellData.includes(item)
          ) {
            // 如果存在，从数组中删除该数字
            const indexToRemove = currentCellData.indexOf(item);
            if (indexToRemove != -1) {
              currentCellData.splice(indexToRemove, 1);
            }
          } else {
            // 如果不存在，将数字追加到数组中
            if (Array.isArray(currentCellData)) {
              // 超过六个不允许
              if (currentCellData.length >= 8) {
                return;
              }
              currentCellData.push(item);
              currentCellData.sort();
            } else {
              // 如果不是数组，创建一个包含数字的新数组
              this.$set(this.sudoUserData, this.selectedIndex, [item]);
            }
          }
          return;
        }
        // 如果不是1-9数字则填充0
        if (/^[1-9]$/.test(this.sudoUserData[this.selectedIndex])) {
          // this.$set(this.sudoUserData, this.selectedIndex, 0);
          this.sudoUserData[this.selectedIndex] = 0;
          this.chooseColor = null;
        } else {
          // this.$set(this.sudoUserData, this.selectedIndex, item);
          this.sudoUserData[this.selectedIndex] = item;
          this.chooseColor = item;
          //调用方法判断删除笔记里面的内容
          this.DeleteNotepad(item);
        }
      } else {
        this.chooseColor = item;
      }
    },
    //获取在哪个小九宫格内
    getGridBox(index) {
      // 计算所在行
      var row = Math.floor((index - 1) / 9);
      // 计算所在列
      var col = (index - 1) % 9;
      // 计算所在九宫格的行和列
      var gridRow = Math.floor(row / 3);
      var gridCol = Math.floor(col / 3);
      // 计算所在九宫格的索引
      var gridIndex = gridRow * 3 + gridCol + 1;
      return gridIndex;
    },
    getGridIndex(gridNumber, elementNumber, sudoItem) {
      //  第几个元素，第几个盒子
      this.sudokuGrids.forEach((item, index) => {
        //获取第几个盒子下面的数据
        if (index === elementNumber - 1) {
          // 注意这里的索引是从 0 开始的 循环遍历那个数组第几个元素
          for (let i = 0; i < item.length; i++) {
            if (Array.isArray(this.sudoUserData[item[i] - 1])) {
              for (let j = 0; j < this.sudoUserData[item[i] - 1].length; j++) {
                if (this.sudoUserData[item[i] - 1][j] == sudoItem) {
                  this.sudoUserData[item[i] - 1].splice(j, 1);
                  j--; // 删除后调整索引
                }
              }
            }
          }
        }
      });
    },
    //删除笔记内容的数组
    getSubgridContent(selectedIndex, sudoItem) {
      //计算当前单元格的行和列
      const row = Math.floor(selectedIndex / 9) + 1;
      const col = (selectedIndex % 9) + 1;
      // 初始化一个数组来存储目标单元格
      const targetCells = [];
      // 确定当前单元格所在的九宫格的起始行和列
      const startRow = Math.floor((row - 1) / 3) * 3 + 1;
      const startCol = Math.floor((col - 1) / 3) * 3 + 1;
      // 根据起始行和列，填充目标单元格数组
      for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
          const index = (startRow + i - 1) * 9 + (startCol - 1 + j);
          targetCells.push(this.sudoUserData[index]);
        }
      }
      // 遍历targetCells数组，查找并更新包含selectedIndex值的单元格
      targetCells.forEach((item, targetIndex) => {
        if (Array.isArray(item)) {
          for (let i = 0; i < item.length; i++) {
            if (item[i] === this.sudoUserData[selectedIndex]) {
              let box = this.getGridBox(selectedIndex + 1);
              this.getGridIndex(targetIndex + 1, box, sudoItem);
            }
          }
        }
      });
    },
    //删除数组里面的笔记内容
    DeleteNotepad(item) {
      const selectedIndex = this.selectedIndex;
      let startRow = Math.floor(selectedIndex / 9) * 9;
      let endRow = startRow + 8;
      let startCol = selectedIndex % 9;

      // 从选定的九宫格中删除项目
      this.getSubgridContent(selectedIndex, item);

      // 从选定的行中删除项目
      for (let i = startRow; i <= endRow; i++) {
        if (Array.isArray(this.sudoUserData[i])) {
          for (let j = 0; j < this.sudoUserData[i].length; j++) {
            if (this.sudoUserData[i][j] === item) {
              this.sudoUserData[i].splice(j, 1);
              j--; // 删除后调整索引
            }
          }
        }
      }

      // 从选定的列中删除项目
      for (let i = startCol; i <= 80; i += 9) {
        if (Array.isArray(this.sudoUserData[i])) {
          for (let j = 0; j < this.sudoUserData[i].length; j++) {
            if (this.sudoUserData[i][j] === item) {
              this.sudoUserData[i].splice(j, 1);
              j--; // 删除后调整索引
            }
          }
        }
      }
    },
  },
  //路由离开
  beforeRouteLeave(to, from, next) {
    //判断是否为竞赛状态,如果为竞赛状态,则把数组数据设置进入本地缓存,防止意外退出
    if (!this.topicRouter) {
      localStorage.setItem("SudoReturn", JSON.stringify(this.sudoUserData));
    }
    //判断是否是修改了本地缓存数据导致异常
    if (localStorage.getItem("leave") != null) {
      next();
      if (this.$websocket) {
        this.$websocket.close();
      }
      localStorage.removeItem("leave");
      return;
    }
    //判断题目是否为9或者23,可以退出比赛
    if (this.topicId == 9 || this.topicId >= 23) {
      next();
      return;
    }
    //提示用户所填入数据不做保留
    if (confirm("所填入数据不做保留")) {
      //关闭长连接
      this.$websocket.close();
      //放行数据
      next();
    } else {
      next(false);
    }
  },
};
</script>
<style lang="less">
:root {
  --back: #20344c; /*背景壁纸颜色*/
  --cell-back: #0c1622; /*单元格背景颜色*/
  --cell-color: #03e9f4; /*单元格颜色*/
  --cell-choose: #248486; /* 单元格匹配颜色*/
  --cell-click: #20344c; /*单元格点击颜色*/
  --cell-number: red; /*单元格文字颜色*/
  --number-mask-color: #03e9f4; /*数字背景颜色*/
  --number-mask: #0c1622; /*数字笔记颜色*/
  --number-color: white; /*数字笔记颜色*/
  --back-color: white; /*全局颜色*/
  --button-color: red; /*按钮颜色*/
  --button-back: #03e9f4;
  --click-size: #d371ea;
}
</style>
<style lang="less" scoped>
#box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  background-color: var(--back);
  font-size: 1vw;
  color: var(--back-color);
  font-family: sans-serif;
}
.left {
  margin-top: 10vh;
  width: 80vw;
  margin-left: 10vw;
  transition: left 0.3s ease;

  /* 添加过渡效果 */
  .student-number {
    position: absolute;
    top: 2vh;
    left: 2vw;
    font-size: 2vw;
    text-align: center;
    padding: 1.5em;
    font-weight: 700;
    color: #fdfdfe;
    text-shadow: 0px 0px 5px #07020c, 0px 0px 10px #07020c, 0px 0px 10px #07020c,
      0px 0px 20px #07020c, 0px 0px 25px #07020c;
    display: flex;

    .time {
      font-size: 3.5vw;
      text-shadow: 0px 0px 5px #c51650, 0px 0px 10px #c51650,
        0px 0px 10px #c51650, 0px 0px 20px #c51650, 0px 0px 25px #c51650;
    }
  }

  .header {
    display: flex;
    margin-top: 10vh;
  }

  .top {
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 3px;
    height: 80vw;
    .cell {
      text-align: center;
      padding: 1vw;
      border: 1px solid black;
      cursor: pointer;
      background-color: var(--cell-back);
      color: var(--cell-color);
      font-family: sans-serif;
      font-size: 3vw;
      height: 3vh;

      &.selected {
        background-color: var(--cell-click);
      }

      &.clickColor {
        color: var(--cell-number);
      }

      &.choose {
        background-color: var(--cell-choose);
        color: white;
      }

      &.clickSize {
        font-size: 1.5vw;
        color: var(--click-size);
      }

      &:nth-child(3n) {
        border-right-width: 4px;
      }

      &:nth-child(3n + 1) {
        border-left-width: 4px;
      }

      &:nth-child(n + 19):nth-child(-n + 27) {
        border-bottom-width: 4px;
      }

      &:nth-child(n + 28):nth-child(-n + 36) {
        border-top-width: 4px;
      }

      &:nth-child(n + 46):nth-child(-n + 54) {
        border-bottom-width: 4px;
      }

      &:nth-child(n + 55):nth-child(-n + 63) {
        border-top-width: 4px;
      }

      &:nth-child(n + 1):nth-child(-n + 9) {
        border-top-width: 4px;
      }

      &:nth-child(n + 73):nth-child(-n + 81) {
        border-bottom-width: 4px;
      }
    }
  }
}
.number {
  width: 95vw;
  height: 10vw;
  display: flex;
  justify-content: space-between;
  align-content: center;

  margin-top: 10vh;
  color: var(--number-color);
  margin-left: -7.5vw;
  font-family: sans-serif;

  .item {
    font-size: 3vw;
    text-align: center;
    padding: 8px;
    border: 1px solid #0c1622;
    cursor: pointer;

    &.chooseNumberColor {
      background-color: var(--number-mask);
      color: var(--number-mask-color);
    }
  }
}

.footer {
  display: flex;
  margin-top: 20vw;
  margin-left: -5vw;
  .el-button {
    width: 24vw;
    align-items: center;
    background-color: #ffffff00;
    color: var(--button-back);

    &.btnClick {
      border-radius: 5px;
      color: #fff;
      background: var(--button-back);
      box-shadow: 0 0 5px 0 #ffcf48, 0 0 25px 0 #ffcf48, 0 0 50px 0 #ffcf48,
        0 0 100px 0 #ffcf48;
      transition: all 1s linear;
    }
  }
}
.show-left {
  margin-left: 10vw;
}

.right {
  position: fixed;
  top: -100vh;
  right: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 半透明背景 */
  transition: right 1s ease;
  /* 添加过渡效果 */
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.update-button {
  position: fixed;
  left: 0;
  bottom: 0;

  .el-button {
    background-color: #ffffff00;
    border: none;
  }
}

.show-rank {
  top: 0;
  /* 展示时滑动到可见位置 */
}

.overlay {
  padding: 20px;
  background-color: black;
  .title {
    text-align: center;
    font-size: 5vw;
    font-weight: bold;
    color: transparent;
    background: url("@/assets/computer/index/hero.png") no-repeat center center;
    background-size: cover;
    -webkit-background-clip: text;
  }
}

.ranking-list {
  zoom: 0.9;

  /deep/ .el-descriptions__body {
    background-color: #ffffff00;
    font-weight: bolder;
    /*设置字体粗细*/
    cursor: pointer;
    /*设置光标形状*/
    color: #fefefe;
    text-shadow: 0 0 0.5em #e62b0a, 0 0 0.2em #5c5c5c;
  }
}
</style>