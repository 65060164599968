<template>
  <div>
    <el-result title="" subTitle="该页面不存在">
      <template slot="icon">
        <el-image
          style="width: 200px; height: 200px"
          :src="require('@/assets/common/soft.png')"
        ></el-image>
        <el-image
          style="width: 220px; height: 200px; margin-left: 20px"
          :src="require('@/assets/common/it.png')"
        ></el-image>
      </template>
      <template slot="extra">
        <el-button @click="ToLogin()" type="primary" size="medium"
          >返回</el-button
        >
      </template>
    </el-result>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // 返回主页
    ToLogin() {
      if (this.$route.path != "/login") {
        this.$router.replace("/login");
      }
    },
  },
};
</script>

<style>
</style>