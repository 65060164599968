<template>
  <div style="zoom: 0.8">
    <div class="center" style="margin-top: 2%">
      <el-table :data="question" :class="{ changeColor: question.id < 9 }">
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="编号" prop="id"></el-table-column>
        <el-table-column label="分数" prop="score"></el-table-column>
        <el-table-column width="250" label="操作" prop="">
          <template slot-scope="scope">
            <div>
              <el-button
                style="background-color: #395667; color: white"
                size="mini"
                @click="UpdateQuestion(question[scope.$index].id)"
                >修改</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      question: [],
      id: "",
    };
  },
  mounted() {
    this.$bus.$on("UpdateQuestion", () => {
      this.GetMemberList();
    });
    this.GetMemberList();
  },
  methods: {
    //删除单条数据
    DeleteMemberOne(id) {
      this.$axios.get(`/Admin/DeleteMemberOne?id=${id}`).then((res) => {
        if (res.data.code == 200) {
          this.$notify.success({ message: "删除成功" });
          this.GetMemberList();
        } else {
          this.$notify.error({ message: "删除失败" });
        }
      });
    },
    //打开详情页并获取详情数据
    UpdateQuestion(id) {
      this.$router.push({
        path: "/manage/question/update",
        query: {
          id: id,
        },
      });
    },
    //获取用户条数和首页用户10条信息
    GetMemberList() {
      this.$axios.get(`/Admin/GetQuestionList`).then(
        (res) => {
          if (res.data.code == 200) {
            this.question = res.data.data;
          }
        },
        (err) => {
          this.$notify.error({ message: "系统维护中..." });
          console.log(err);
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.center {
  &.changeColor {
    color: red;
  }
}
.footer {
  margin-top: 2%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2vh;
}
</style>