<template>
  <div>
    <el-dialog
      title="新增活动数据"
      :visible.sync="dialogFormVisible"
      :before-close="beforeClose"
    >
      <el-form :model="form" label-width="200px" ref="formName">
        <el-form-item label="编号">
          <el-input
            size="mini"
            style="width: 80%"
            v-model="form.history.id"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="活动名称">
          <el-input
            size="mini"
            style="width: 80%"
            v-model="form.history.historyTitle"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动主题">
          <el-input
            size="mini"
            style="width: 80%"
            v-model="form.history.historySubTitle"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动时间">
          <el-date-picker
            type="datetime"
            v-model="form.history.historyTime"
            placeholder="选择日期时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="活动描述">
          <el-input
            type="textarea"
            size="mini"
            style="width: 80%"
            v-model="form.history.historyDescription"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动声明文件">
          <div style="display: flex">
            <el-upload
              :limit="1"
              :on-success="onSuccessPdf"
              :headers="headers"
              :action="action + '/file/upload-pdf'"
              :before-upload="beforeUploadPDF"
            >
              <el-button>点击上传</el-button>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="活动封面图片">
          <div style="display: flex">
            <el-upload
              list-type="picture-card"
              :limit="1"
              :on-success="onSuccessImg"
              :headers="headers"
              :before-upload="beforeUploadJPG"
              :action="action + '/file/upload-img'"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="活动竞赛排名">
          <div style="display: flex">
            <el-upload
              :limit="1"
              :on-success="onSuccessExcel"
              :before-upload="beforeUploadExcel"
              :headers="headers"
              :action="action + '/file/upload-excel'"
            >
              <el-button>点击上传</el-button>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="活动记录">
          <quillEditor v-model="form.detail.detailFile"></quillEditor>
        </el-form-item>
        <el-form-item label="活动详情页描述">
          <el-input
            size="mini"
            style="width: 80%"
            v-model="form.detail.description"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="beforeClose">取 消</el-button>
        <el-button size="mini" type="primary" @click="submit()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
1
<script>
import { quillEditor } from "vue-quill-editor";

export default {
  components: { quillEditor },
  data() {
    return {
      dialogFormVisible: true, //是否显示
      form: {
        history: {
          id: "", //编号数据
          detailId: "", //活动详情页id
          historyTitle: "", //活动名称
          historySubTitle: "", //活动主题
          historyTime: "", //活动时间
          historyDescription: "", //活动描述
          historyFile: "", //活动文件地址
          historyImg: "", //活动封面地址
        },
        detail: {
          id: "", //活动详情页id数据
          detailExcel: "", //活动竞赛排名
          detailFile: "", //活动记录数据
          description: "", //活动详情页描述
        }, //活动详情数据
      },
      action: this.$axios.defaults.baseURL, //服务器路径
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }, //请求头数据
    };
  },
  mounted() {},
  methods: {
    //提交数据
    submit() {
      //进行校验
      this.$axios.post("/Admin/AdminUploadHistory", this.form).then((res) => {
        //上传成功
        if (res.data.code == 200) {
          //回退路由
          this.$notify.success({ message: res.data.data });
          this.beforeClose();
          this.$bus.$emit("UpdateHistory");
          return;
        }
        this.$notify.error({ message: res.data.msg });
      });
    },
    //文件上传校验
    beforeUploadJPG(file) {
      const isJPG =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$notify.error("上传只能为 PNG JPG JPEG 格式!");
      }
      if (!isLt2M) {
        this.$notify.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //文件上传校验
    beforeUploadExcel(file) {
      const isJPG =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$notify.error("上传只能为 XLSX 格式!");
      }
      if (!isLt2M) {
        this.$notify.error("上传表格大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //文件上传校验
    beforeUploadPDF(file) {
      const isPDF = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isPDF) {
        this.$notify.error("上传只能为 PDF 格式!");
      }
      if (!isLt2M) {
        this.$notify.error("上传PDF大小不能超过 2MB!");
      }
      return isPDF && isLt2M;
    },
    //文件上传成功的回调
    onSuccessImg(res) {
      //如果文件上传失败
      if (res.code != 200) {
        this.$notify.error({ message: res.msg });
        this.fileListPdf = [];
        return;
      }
      //将文件路径赋值给表单
      this.form.history.historyImg = res.data;
      this.$notify.success({ message: "上传成功" });
    },
    //文件上传成功的回调
    onSuccessPdf(res) {
      //如果文件上传失败
      if (res.code != 200) {
        this.$notify.error({ message: res.msg });
        return;
      }
      //将文件路径赋值给表单
      this.form.history.historyFile = res.data;
      this.$notify.success({ message: "上传成功" });
    },
    //文件上传成功的回调
    onSuccessExcel(res) {
      //如果文件上传失败
      if (res.code != 200) {
        this.$notify.error({ message: res.msg });
        return;
      }
      //将文件路径赋值给表单
      this.form.detail.detailExcel = res.data;
      this.$notify.success({ message: "上传成功" });
    },
    //路由回退
    beforeClose() {
      this.$router.back();
    },
  },
};
</script>

<style>
</style>