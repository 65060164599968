<template>
  <div>
    <el-dialog
      title="修改活动数据"
      :visible.sync="dialogFormVisible"
      :before-close="beforeClose"
    >
      <el-form :model="form" label-width="200px" ref="formName">
        <el-form-item label="编号">
          <el-input
            size="mini"
            style="width: 80%"
            v-model="form.history.id"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="活动名称">
          <el-input
            size="mini"
            style="width: 80%"
            v-model="form.history.historyTitle"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动主题">
          <el-input
            size="mini"
            style="width: 80%"
            v-model="form.history.historySubTitle"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动时间">
          <el-date-picker
            type="datetime"
            v-model="form.history.historyTime"
            placeholder="选择日期时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="活动描述">
          <el-input
            type="textarea"
            size="mini"
            style="width: 80%"
            v-model="form.history.historyDescription"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动声明文件">
          <div style="display: flex">
            <el-upload
              :limit="1"
              :on-success="onSuccessPdf"
              :headers="headers"
              :action="action + '/file/upload-pdf'"
              :before-upload="beforeUploadPDF"
            >
              <el-button>点击上传</el-button>
              <div
                slot="tip"
                style="cursor: pointer"
                @click="OpenGamePdf(form.history.historyFile)"
              >
                文件路径（点击查看）：{{ form.history.historyFile }}
              </div>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="活动封面图片">
          <div style="display: flex; width: 550px; height: 150px">
            <el-upload
              list-type="picture-card"
              :limit="1"
              :on-success="onSuccessImg"
              :headers="headers"
              :before-upload="beforeUploadJPG"
              :action="action + '/file/upload-img'"
            >
              <img
                v-if="
                  form.history.historyImg != '' &&
                  tempImg == form.history.historyImg
                "
                style="width: 100%; height: 100%"
                :src="webURL + form.history.historyImg"
                alt=""
              />
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="第一名">
          <div style="display: flex; width: 550px; height: 150px">
            <el-upload
              list-type="picture-card"
              :limit="1"
              :on-success="onSuccessImgOne"
              :headers="headers"
              :before-upload="beforeUploadJPG"
              :action="action + '/file/upload-img'"
            >
              <img
                v-if="
                  form.detail.one != null &&
                  form.detail.one != null &&
                  one == form.detail.one
                "
                style="width: 100%; height: 100%"
                :src="webURL + form.detail.one"
                alt=""
              />
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="第二名">
          <div style="display: flex; width: 550px; height: 150px">
            <el-upload
              list-type="picture-card"
              :limit="1"
              :on-success="onSuccessImgTwo"
              :headers="headers"
              :before-upload="beforeUploadJPG"
              :action="action + '/file/upload-img'"
            >
              <img
                v-if="
                  form.detail.two != null &&
                  form.detail.two != null &&
                  two == form.detail.two
                "
                style="width: 100%; height: 100%"
                :src="webURL + form.detail.two"
                alt=""
              />
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="第三名">
          <div style="display: flex; width: 550px; height: 150px">
            <el-upload
              list-type="picture-card"
              :limit="1"
              :on-success="onSuccessImgThree"
              :headers="headers"
              :before-upload="beforeUploadJPG"
              :action="action + '/file/upload-img'"
            >
              <img
                v-if="
                  form.detail.three != null &&
                  form.detail.three != null &&
                  three == form.detail.three
                "
                style="width: 100%; height: 100%"
                :src="webURL + form.detail.three"
                alt=""
              />
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="第四名">
          <div style="display: flex; width: 550px; height: 150px">
            <el-upload
              list-type="picture-card"
              :limit="1"
              :on-success="onSuccessImgFour"
              :headers="headers"
              :before-upload="beforeUploadJPG"
              :action="action + '/file/upload-img'"
            >
              <img
                v-if="
                  form.detail.four != null &&
                  form.detail.four != null &&
                  four == form.detail.four
                "
                style="width: 100%; height: 100%"
                :src="webURL + form.detail.four"
                alt=""
              />
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="第五名">
          <div style="display: flex; width: 550px; height: 150px">
            <el-upload
              list-type="picture-card"
              :limit="1"
              :on-success="onSuccessImgFive"
              :headers="headers"
              :before-upload="beforeUploadJPG"
              :action="action + '/file/upload-img'"
            >
              <img
                v-if="
                  form.detail.five != null &&
                  form.detail.five != null &&
                  five == form.detail.five
                "
                style="width: 100%; height: 100%"
                :src="webURL + form.detail.five"
                alt=""
              />
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="第六名">
          <div style="display: flex; width: 550px; height: 150px">
            <el-upload
              list-type="picture-card"
              :limit="1"
              :on-success="onSuccessImgSix"
              :headers="headers"
              :before-upload="beforeUploadJPG"
              :action="action + '/file/upload-img'"
            >
              <img
                v-if="
                  form.detail.six != null &&
                  form.detail.six != null &&
                  six == form.detail.six
                "
                style="width: 100%; height: 100%"
                :src="webURL + form.detail.six"
                alt=""
              />
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="活动竞赛排名">
          <div style="display: flex">
            <el-upload
              :limit="1"
              :on-success="onSuccessExcel"
              :before-upload="beforeUploadExcel"
              :headers="headers"
              :action="action + '/file/upload-excel'"
            >
              <el-button>点击上传</el-button>
              <div
                slot="tip"
                style="cursor: pointer"
                @click="DownLoadExcel(form.detail.detailExcel)"
              >
                文件路径（点击查看）：{{ form.detail.detailExcel }}
              </div>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="活动记录">
          <quillEditor v-model="form.detail.detailFile"></quillEditor>
        </el-form-item>
        <el-form-item label="活动详情页描述">
          <el-input
            size="mini"
            style="width: 80%"
            v-model="form.detail.description"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="beforeClose">取 消</el-button>
        <el-button size="mini" type="primary" @click="submit()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";

export default {
  components: { quillEditor },
  data() {
    return {
      dialogFormVisible: true, //是否显示
      form: {
        history: {
          id: "", //编号数据
          detailId: "", //活动详情页id
          historyTitle: "", //活动名称
          historySubTitle: "", //活动主题
          historyTime: "", //活动时间
          historyDescription: "", //活动描述
          historyFile: "", //活动文件地址
          historyImg: "", //活动封面地址
        },
        detail: {
          id: "", //活动详情页id数据
          detailExcel: "", //活动竞赛排名
          detailFile: "", //活动记录数据
          description: "", //活动详情页描述
        }, //活动详情数据
      },
      action: this.$axios.defaults.baseURL, //服务器路径
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }, //请求头数据
      webURL: this.$axios.defaults.webURL, //前端路径
      tempImg: "", //临时图片路径
      one: "", //临时图片路径
      two: "", //临时图片路径
      three: "", //临时图片路径
      four: "", //临时图片路径
      five: "", //临时图片路径
      six: "", //临时图片路径
    };
  },
  mounted() {
    //设置id数据
    this.form.history.id = this.$route.query.id;
    //请求表单数据
    this.AdminGetHistoryDetail();
  },
  methods: {
    //下载文件
    DownLoadExcel(detailExcel) {
      window.open(this.webURL + detailExcel);
    },
    //打开数独大赛文件
    OpenGamePdf(historyFile) {
      window.open(this.webURL + historyFile);
    },
    //请求表单数据
    AdminGetHistoryDetail() {
      //请求表单数据
      this.$axios
        .get(`/Admin/AdminGetHistoryDetail?id=${this.form.history.id}`)
        .then((res) => {
          this.form.history = res.data.data.history;
          this.form.detail = res.data.data.detail;
          this.tempImg = this.form.history.historyImg;
          this.one = this.form.detail.one;
          this.two = this.form.detail.two;
          this.three = this.form.detail.three;
          this.four = this.form.detail.four;
          this.five = this.form.detail.five;
          this.six = this.form.detail.six;
        });
    },
    //提交数据
    submit() {
      //进行校验
      this.$axios.post("/Admin/AdminUpdateHistory", this.form).then((res) => {
        //上传成功
        if (res.data.code == 200) {
          //回退路由
          this.$notify.success({ message: res.data.data });
          this.beforeClose();
          this.$bus.$emit("UpdateHistory");
          return;
        }
        this.$notify.error({ message: res.data.msg });
      });
    },
    //文件上传校验
    beforeUploadJPG(file) {
      const isJPG =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$notify.error("上传只能为 PNG JPG JPEG 格式!");
      }
      if (!isLt2M) {
        this.$notify.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //文件上传校验
    beforeUploadExcel(file) {
      const isJPG =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$notify.error("上传只能为 XLSX 格式!");
      }
      if (!isLt2M) {
        this.$notify.error("上传表格大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //文件上传校验
    beforeUploadPDF(file) {
      const isPDF = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isPDF) {
        this.$notify.error("上传只能为 PDF 格式!");
      }
      if (!isLt2M) {
        this.$notify.error("上传PDF大小不能超过 2MB!");
      }
      return isPDF && isLt2M;
    },
    //文件上传成功的回调
    onSuccessImg(res) {
      //如果文件上传失败
      if (res.code != 200) {
        this.$notify.error({ message: res.msg });
        this.fileListPdf = [];
        return;
      }
      //将文件路径赋值给表单
      this.form.history.historyImg = res.data;
      this.$notify.success({ message: "上传成功" });
    },
    onSuccessImgOne(res) {
      //如果文件上传失败
      if (res.code != 200) {
        this.$notify.error({ message: res.msg });
        return;
      }
      //将文件路径赋值给表单
      this.form.detail.one = res.data;
      this.$notify.success({ message: "上传成功" });
    },
    onSuccessImgTwo(res) {
      //如果文件上传失败
      if (res.code != 200) {
        this.$notify.error({ message: res.msg });
        return;
      }
      //将文件路径赋值给表单
      this.form.detail.two = res.data;
      this.$notify.success({ message: "上传成功" });
    },
    onSuccessImgThree(res) {
      //如果文件上传失败
      if (res.code != 200) {
        this.$notify.error({ message: res.msg });
        return;
      }
      //将文件路径赋值给表单
      this.form.detail.thtee = res.data;
      this.$notify.success({ message: "上传成功" });
    },
    onSuccessImgFour(res) {
      //如果文件上传失败
      if (res.code != 200) {
        this.$notify.error({ message: res.msg });
        return;
      }
      //将文件路径赋值给表单
      this.form.detail.four = res.data;
      this.$notify.success({ message: "上传成功" });
    },
    onSuccessImgFive(res) {
      //如果文件上传失败
      if (res.code != 200) {
        this.$notify.error({ message: res.msg });
        return;
      }
      //将文件路径赋值给表单
      this.form.detail.five = res.data;
      this.$notify.success({ message: "上传成功" });
    },
    onSuccessImgSix(res) {
      //如果文件上传失败
      if (res.code != 200) {
        this.$notify.error({ message: res.msg });
        return;
      }
      //将文件路径赋值给表单
      this.form.detail.six = res.data;
      this.$notify.success({ message: "上传成功" });
    },
    //文件上传成功的回调
    onSuccessPdf(res) {
      //如果文件上传失败
      if (res.code != 200) {
        this.$notify.error({ message: res.msg });
        return;
      }
      //将文件路径赋值给表单
      this.form.history.historyFile = res.data;
      this.$notify.success({ message: "上传成功" });
    },
    //文件上传成功的回调
    onSuccessExcel(res) {
      //如果文件上传失败
      if (res.code != 200) {
        this.$notify.error({ message: res.msg });
        return;
      }
      //将文件路径赋值给表单
      this.form.detail.detailExcel = res.data;
      this.$notify.success({ message: "上传成功" });
    },
    //路由回退
    beforeClose() {
      this.$router.back();
    },
  },
};
</script>

<style>
</style>