<template>
  <div></div>
</template>

<script>
import Vue from "vue";
export default {
  data() {
    return {
      baseURL: this.$axios.defaults.baseURL, //api连接路径信息
      id: localStorage.getItem("id"), //用户的id信息
      g_chatInterval: null, //心跳包计时器
      heartCount: 0, //心跳包重连10次后禁止重连
    };
  },
  mounted() {
    //判断路径,禁止连接
    this.init();
  },
  methods: {
    //初始化信息,判断路径禁止连接
    init() {
      //判断是否为home,index路径
      let path = this.$route.path;
      if (path != "/home" && path != "/login") {
        this.WebSocketWeb();
      }
      //进行重连操作
      this.$bus.$on("RestartConnSocket", () => {
        this.WebSocketWeb();
      });
    },
    //建立websocket连接
    WebSocketWeb() {
      //创建一个长连接
      let websocket = null;
      let url = this.$axios.defaults.baseURL;
      if ("WebSocket" in window) {
        if (url.startsWith("http://")) {
          url = url.slice(7);
          websocket = new WebSocket(`ws://${url}/ws/${this.id}`);
        }
        if (url.startsWith("https://")) {
          url = url.slice(8);
          websocket = new WebSocket(`wss://${url}/ws/${this.id}`);
        }
      } else {
        alert("此网站不支持WebSocket连接，但不影响竞赛正常进行！");
      }
      websocket.onopen = () => {
        //用户成功进行连接,连接后进行原型绑定操作
        this.$notify.success({
          message: "竞赛通知成功建立竞赛连接",
          position: "top-left",
        });
        //进行原型绑定操作
        Vue.prototype.$websocket = websocket;
        //开始发送心跳包维持连接
        console.log("连接成功！");
        this.SendHeatMessage();
      };
      websocket.onmessage = (e) => {
        this.ConnMessage(e);
      };
      // 关闭连接
      websocket.onclose = async (err) => {
        //连接关闭,提醒用户联系管理员
        console.log(err);
        //判断是否在主页面
        if (this.$route.path == "/index") {
          this.$notify.error({
            message: "竞赛通知连接关闭，不影响竞赛正常进行！",
            position: "bottom-right",
          });
        }
        this.$websocket = null;
      };
      //连接发生错误
      websocket.onerror = async (err) => {
        //连接异常,提醒用户联系管理员
        console.log(err);
        this.$notify.error({
          message: "竞赛通知连接异常，不影响竞赛正常进行！",
          position: "bottom-right",
        });
        this.$websocket = null;
      };
    },
    //开始发送心跳包维持连接
    async SendHeatMessage() {
      //判断是否为null
      if (this.$websocket == null || this.$websocket == undefined) {
        return;
      }
      this.$websocket.send("ping");
      //每隔三秒重新发送
      await this.sleep();
      this.SendHeatMessage();
    },
    //设置停顿时间
    async sleep() {
      return new Promise((resolve) => {
        setTimeout(resolve, 30000);
      });
    },
    //接收到连接消息
    ConnMessage(e) {
      //收到消息进行判断
      //判断是否是重新获取排行榜数据
      if (e.data == "pong") {
        console.log("收到竞赛pong，拒绝处理！");
        return;
      }
      if (e.data == "getRanking") {
        this.$bus.$emit("getRanking");
      }
      //监听管理员将用户提出比赛操作
      if (e.data == "kickOut") {
        this.$bus.$emit("kickOut");
      }
      //监听管理员修改比赛状态
      if (e.data == "adminUpdateStatus") {
        this.$bus.$emit("adminUpdateStatus");
      }
      //监听管理员修改比赛状态
      if (e.data == "kickOutAllUser") {
        this.$bus.$emit("kickOutAllUser");
      }
    },
  },
};
</script>

<style>
</style>